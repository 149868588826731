import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Reorder from 'react-reorder'
import Editor from '../components/editer'

class ContactUsUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">ติดต่อเรา</p>
                {(attr.loading_info || attr.loading_contact) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    [
                        <div className="card mb-4" key={0}>
                            <div className="card-body">
                                <form id="submit-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">ที่อยู่ (TH)</label>
                                            <div className='mb-3'>
                                                <Editor data={data.address_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "address_th" })} disabled={attr.disabled} />
                                                <input type="hidden" id="address_th" name="address_th" value={data.address_th} required />
                                            </div>
                                            <Editor data={data.address_2_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "address_2_th" })} disabled={attr.disabled} />
                                            <input type="hidden" id="address_2_th" name="address_2_th" value={data.address_2_th} required />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">ที่อยู่ (EN)</label>
                                            <div className='mb-3'>
                                                <Editor data={data.address_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "address_en" })} disabled={attr.disabled} />
                                                <input type="hidden" id="address_en" name="address_en" value={data.address_en} required />
                                            </div>
                                            <Editor data={data.address_2_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "address_2_en" })} disabled={attr.disabled} />
                                            <input type="hidden" id="address_2_en" name="address_2_en" value={data.address_2_en} required />
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn btn-success" onClick={this.props.handleSaveAddress}>บันทึก</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div className="card" key={1}>
                            <div className="card-body">
                                <h6 className="form-group">ช่องทางติดต่อ</h6>
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.props.handleShowAdd}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-60px">ลำดับ</th>
                                                <th scope="col" className="w-60px"></th>
                                                <th scope="col" className="w-100px">รูปภาพ</th>
                                                <th scope="col" className="w-auto-divide-3">ข้อความ (TH)</th>
                                                <th scope="col" className="w-auto-divide-3">ข้อความ (EN)</th>
                                                <th scope="col" className="w-auto-divide-3">ลิงค์</th>
                                                <th scope="col" className="text-center w-160px">ตัวเลือก</th>
                                            </tr>
                                        </thead>
                                        <Reorder reorderId="my-list" component="tbody" onReorder={this.props.handleReorder} draggedClassName="d-flex shadow tr-td-bottom-0 align-items-center bg-white"
                                            placeholder={
                                                <tr className="bg-gray">
                                                    <td height="75px" colSpan="7"></td>
                                                </tr>
                                            }>
                                            {this.props.state.list.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="w-60px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{i + 1}</td>
                                                        <td className="w-60px"><i className="fa fa-bars pointer fa-2x"></i></td>
                                                        <td className="w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <img src={item.old_image} alt="thumbnail" height="50px" />
                                                        </td>
                                                        <td className="w-auto-divide-3" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.title_th}</p>
                                                            <p>{item.word_th}</p>
                                                        </td>
                                                        <td className="w-auto-divide-3" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.title_en}</p>
                                                            <p>{item.word_en}</p>
                                                        </td>
                                                        <td className="w-auto-divide-3" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p className="truncate">{item.link}</p>
                                                        </td>
                                                        <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <button className="btn btn-warning mr-2" onClick={() => this.props.handleUpdate(i)}>แก้ไข</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => this.props.handleDetele(i)}>ลบ</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </Reorder>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ]
                }
            </Fragment>
        )
    }
}

export default withRouter(ContactUsUI)

