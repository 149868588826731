import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Reorder from 'react-reorder'
import Editor from '../components/editer'
import CONS from "../constants"

class KnowUsUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">รู้จักเรา</p>
                {(attr.loading_info || attr.loading_team) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    [
                        <div className="card mb-4" key={0}>
                            <div className="card-body">
                                <h6 className="form-group">ข้อความบนแบล็คกราวน์</h6>
                                <form id="word-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">ข้อความ (TH)</label>
                                            <input type="text" className="form-control" name="knowus_bg_word_th" value={data.knowus_bg_word_th} onChange={this.props.handleChangeData} required />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">ข้อความ (EN)</label>
                                            <input type="text" className="form-control" name="knowus_bg_word_en" value={data.knowus_bg_word_en} onChange={this.props.handleChangeData} required />
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn btn-success" onClick={this.props.handleSaveWord}>บันทึก</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div className="card mb-4" key={1}>
                            <div className="card-body">
                                <form id="bg-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">รูปภาพแบล็คกราวน์</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="knowus_bg" name="knowus_bg" onChange={this.props.handleChangeFile} required={(data.old_knowus_bg !== "" && data.old_knowus_bg !== undefined) ? false : true} />
                                                <label className="custom-file-label" htmlFor="knowus_bg">{data.name_knowus_bg}</label>
                                                <input type="hidden" id="old_knowus_bg" name="old_knowus_bg" defaultValue={data.old_knowus_bg} />
                                            </div>
                                            <small className="form-text text-muted">รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB</small>
                                            {(data.old_knowus_bg !== "" && data.old_knowus_bg !== undefined) ? <img src={data.old_knowus_bg} alt="thumbnail" className="w-100 max-w-400px" /> : null}
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn btn-success" onClick={this.props.handleSaveBg}>บันทึก</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div className="card mb-4" key={2}>
                            <div className="card-body">
                                <h6 className="form-group">รู้จักเรา</h6>
                                <form id="knowus-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">หัวเรื่อง (TH)</label>
                                            <input type="text" className="form-control" name="knowus_title_th" value={data.knowus_title_th} onChange={this.props.handleChangeData} required />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">หัวเรื่อง (EN)</label>
                                            <input type="text" className="form-control" name="knowus_title_en" value={data.knowus_title_en} onChange={this.props.handleChangeData} required />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">เนื้อหา (TH)</label>
                                            <Editor data={data.knowus_description_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "knowus_description_th" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="knowus_description_th" name="knowus_description_th" value={data.knowus_description_th} required/>
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">เนื้อหา (EN)</label>
                                            <Editor data={data.knowus_description_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "knowus_description_en" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="knowus_description_en" name="knowus_description_en" value={data.knowus_description_en} required/>
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn btn-success" onClick={this.props.handleSaveKnowUs}>บันทึก</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div className="card" key={3}>
                            <div className="card-body">
                                <h6 className="form-group">ทีมงานของเรา</h6>
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.props.handleShowAdd}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-60px">ลำดับ</th>
                                                <th scope="col" className="w-60px"></th>
                                                <th scope="col" className="w-100px">รูปภาพ</th>
                                                <th scope="col" className="w-auto-divide-team">ข้อความ (TH)</th>
                                                <th scope="col" className="w-auto-divide-team">ข้อความ (EN)</th>
                                                <th scope="col" className="text-center w-160px">ตัวเลือก</th>
                                            </tr>
                                        </thead>
                                        <Reorder reorderId="my-list" component="tbody" onReorder={this.props.handleReorder} draggedClassName="d-flex shadow tr-td-bottom-0 align-items-center bg-white"
                                            placeholder={
                                                <tr className="bg-gray">
                                                    <td height="75px" colSpan="7"></td>
                                                </tr>
                                            }>
                                            {this.props.state.list.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="w-60px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{i + 1}</td>
                                                        <td className="w-60px"><i className="fa fa-bars pointer fa-2x"></i></td>
                                                        <td className="w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <img src={item.old_image} alt="thumbnail" height="50px" />
                                                        </td>
                                                        <td className="w-auto-divide-team" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.name_th}</p>
                                                            <span dangerouslySetInnerHTML={{ __html: item.position_th }}></span>
                                                        </td>
                                                        <td className="w-auto-divide-team" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.name_en}</p>
                                                            <span dangerouslySetInnerHTML={{ __html: item.position_en }}></span>
                                                        </td>
                                                        <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <button className="btn btn-warning mr-2" onClick={() => this.props.handleUpdate(i)}>แก้ไข</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => this.props.handleDetele(i)}>ลบ</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </Reorder>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ]
                }
            </Fragment>
        )
    }
}

export default withRouter(KnowUsUI)

