import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter, Link } from "react-router-dom"
import jwt_decode from 'jwt-decode'
import Alert from "../functions/alert"
import ChangePassModal from '../components/change-pass-modal'
import CONS from "../constants"

class MainLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                unauthorized: true,
                toggle: (window.innerWidth < 769) ? false : true
            },
            info: {
                fullname: "",
                access_token: "",
                permission_menu: {
                    registers: 0,
                    popup: 0,
                    seo: 0,
                    faq: 0,
                    staff: 0
                }
            }
        }
    }

    handleToggle = () => {
        this.setState({ attr: { ...this.state.attr, toggle: (this.state.attr.toggle ? false : true) } })
    }

    handleChangePassword = () => {
        ReactDOM.render(
            <ChangePassModal
                access_token={this.state.info.access_token}
                callBack={() => {
                    Alert.showMsg("เปลี่ยนรหัสผ่านสำเร็จ กรุณาเข้าระบบอีกครั้งด้วยรหัสผ่านใหม่")
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login')
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleLogOut = () => {
        localStorage.removeItem('login_token')
        this.props.history.push(CONS.URL_LINK + '/login')
    }

    checkToken = () => {
        var access_token = localStorage.getItem('login_token')
        if (access_token != null) {
            var token = jwt_decode(access_token)
            var permission_menu = JSON.parse(token.permission_menu)
            var state = this.state
            state.info.fullname = token.fullname
            state.info.access_token = access_token
            state.info.permission_menu = permission_menu
            if ((this.props.authorized === "registers" && permission_menu.registers)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "popup" && permission_menu.popup)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "seo" && permission_menu.seo)) {
                state.attr.unauthorized = false
            }
            // if ((this.props.authorized === "faq" && permission_menu.faq)) {
            //     state.attr.unauthorized = false
            // }
            if ((this.props.authorized === "content" && permission_menu.content)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "home" && permission_menu.home)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "knowus" && permission_menu.knowus)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "services" && permission_menu.services)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "servicecharge" && permission_menu.servicecharge)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "news" && permission_menu.news)) {
                state.attr.unauthorized = false
            }
            // if ((this.props.authorized === "gallery" && permission_menu.gallery)) {
            //     state.attr.unauthorized = false
            // }
            if ((this.props.authorized === "contactus" && permission_menu.contactus)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "callaction" && permission_menu.callaction)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "staff" && permission_menu.staff)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "branch" && permission_menu.branch)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "cookie" && permission_menu.cookie)) {
                state.attr.unauthorized = false
            }
            if ((this.props.authorized === "reportproblem" && permission_menu.reportproblem)) {
                state.attr.unauthorized = false
            }
            this.setState({ state })
            if (this.props.onCheckToken !== undefined) this.props.onCheckToken(access_token)
        } else {
            localStorage.removeItem('login_token')
            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
        }
    }

    UNSAFE_componentWillMount() {
        var alert = localStorage.getItem('alert')
        if (alert !== null) {
            Alert.showMsg(alert)
            localStorage.removeItem('alert')
        }
        this.checkToken()
    }

    render() {
        return (
            <div className="d-flex">
                <div className="bg-menu-left">
                    <nav className={"nav flex-column nav-menu-left pb-5" + (this.state.attr.toggle ? " toggle" : "")}>
                        <div className="h-46px">
                            <h3 className="mb-0 text-white">&nbsp;</h3>
                        </div>
                        {(this.state.info.permission_menu.registers === 1) ?
                            <Link className={"nav-link" + (this.props.active === "registers" ? " active" : "")} to={CONS.URL_LINK + "/registers"}>
                                รายชื่อผู้ลงทะเบียน
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.reportproblem === 1) ?
                            <Link className={"nav-link" + (this.props.active === "reportproblem" ? " active" : "")} to={CONS.URL_LINK + "/report/problem"}>
                                รายการแจ้งปัญหา
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.home === 1) ?
                            <Link className={"nav-link" + (this.props.active === "home" ? " active" : "")} to={CONS.URL_LINK + "/home"}>
                                หน้าแรก
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.knowus === 1) ?
                            <Link className={"nav-link" + (this.props.active === "knowus" ? " active" : "")} to={CONS.URL_LINK + "/knowus"}>
                                รู้จักเรา
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.services === 1) ?
                            <Link className={"nav-link" + (this.props.active === "services" ? " active" : "")} to={CONS.URL_LINK + "/services"}>
                                บริการ
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.servicecharge === 1) ?
                            <Link className={"nav-link" + (this.props.active === "servicecharge" ? " active" : "")} to={CONS.URL_LINK + "/servicecharge"}>
                                ค่าบริการ
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.branch === 1) ?
                            <Link className={"nav-link" + (this.props.active === "branch" ? " active" : "")} to={CONS.URL_LINK + "/branch"}>
                                สาขา
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.news === 1) ?
                            <Link className={"nav-link" + (this.props.active === "news" ? " active" : "")} to={CONS.URL_LINK + "/news"}>
                                ข่าวสารกิจกรรม
                            </Link>
                            : null
                        }
                        {/* {(this.state.info.permission_menu.gallery === 1) ?
                            <Link className={"nav-link" + (this.props.active === "gallery" ? " active" : "")} to={CONS.URL_LINK + "/gallery"}>
                                แกลลอรี่
                            </Link>
                            : null
                        } */}
                        {/* {(this.state.info.permission_menu.faq === 1) ?
                            <Link className={"nav-link" + (this.props.active === "faq" ? " active" : "")} to={CONS.URL_LINK + "/faq"}>
                                คำถามที่พบบ่อย
                            </Link>
                            : null
                        } */}
                        {(this.state.info.permission_menu.content === 1) ?
                            <Link className={"nav-link" + (this.props.active === "content" ? " active" : "")} to={CONS.URL_LINK + "/content"}>
                                บทความ
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.contactus === 1) ?
                            <Link className={"nav-link" + (this.props.active === "contactus" ? " active" : "")} to={CONS.URL_LINK + "/contactus"}>
                                ติดต่อเรา
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.popup === 1) ?
                            <Link className={"nav-link" + (this.props.active === "popup" ? " active" : "")} to={CONS.URL_LINK + "/popup"}>
                                ตั้งค่า Pop-up
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.callaction === 1) ?
                            <Link className={"nav-link" + (this.props.active === "callaction" ? " active" : "")} to={CONS.URL_LINK + "/call-action"}>
                                ตั้งค่า Call-to-action
                            </Link>
                            : null
                        }
                        {(this.state.info.permission_menu.seo === 1) ?
                            [
                                <a key={0} className={"nav-link" + (this.props.active === "seo" ? " active" : "")} data-toggle="collapse" href="#menu_seo" role="button" aria-expanded="false" aria-controls="menu_seo">
                                    ตั้งค่า SEO
                                </a>,
                                <div key={1} className={"collapse" + (this.props.active.match(/seo/g) ? " show" : "")} id="menu_seo">
                                    <nav className="nav flex-column nav-menu-left-sub">
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/home" ? " active" : "")} to={CONS.URL_LINK + "/seo/home"}>- หน้าแรก</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/knowus" ? " active" : "")} to={CONS.URL_LINK + "/seo/knowus"}>- รู้จักเรา</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/service" ? " active" : "")} to={CONS.URL_LINK + "/seo/service"}>- บริการ</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/servicecharge" ? " active" : "")} to={CONS.URL_LINK + "/seo/servicecharge"}>- ค่าบริการ</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/news" ? " active" : "")} to={CONS.URL_LINK + "/seo/news"}>- ข่าวสารกิจกรรม</Link>
                                        {/* <Link className={"nav-link pl-4" + (this.props.active === "seo/gallery" ? " active" : "")} to={CONS.URL_LINK + "/seo/gallery"}>- แกลลอรี่</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/faq" ? " active" : "")} to={CONS.URL_LINK + "/seo/faq"}>- คำถามที่พบบ่อย</Link> */}
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/branches" ? " active" : "")} to={CONS.URL_LINK + "/seo/branches"}>- สาขา</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/content" ? " active" : "")} to={CONS.URL_LINK + "/seo/content"}>- บทความ</Link>
                                        <Link className={"nav-link pl-4" + (this.props.active === "seo/contactus" ? " active" : "")} to={CONS.URL_LINK + "/seo/contactus"}>- ติดต่อเรา</Link>
                                    </nav>
                                </div>
                            ]
                            : null
                        }
                        {(this.state.info.permission_menu.cookie === 1) ?
                            <Link className={"nav-link" + (this.props.active === "cookie" ? " active" : "")} to={CONS.URL_LINK + "/cookie"}>
                                นโยบายคุ๊กกี้
                            </Link>
                            : null
                        }
                        {/* {(this.state.info.permission_menu.staff === 1) ?
                            <Link className={"nav-link" + (this.props.active === "staff" ? " active" : "")} to={CONS.URL_LINK + "/staff"}>
                                ตั้งค่าผู้ใช้งาน
                            </Link>
                            : null
                        } */}
                    </nav>
                </div>
                <div className="col px-0">
                    <nav className="navbar navbar-header">
                        <div className="pointer" onClick={this.handleToggle}>
                            <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
                        </div>
                        <div className="mx-0">
                            <a id="btn-control" href="/" className="dropdown-toggle pr-0 pr-lg-2 pointer h-24px text-dark" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                <p className="mb-0 pr-2 d-inline-block">{this.state.info.fullname}</p>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-right mt-0 mr-2">
                                <div className="dropdown-item pointer py-2 border-bottom" onClick={this.handleChangePassword}>Change Password</div>
                                <div className="dropdown-item pointer py-2" onClick={this.handleLogOut}>Log Out</div>
                            </div>
                        </div>
                    </nav>
                    <div className="container-fluid mb-5" id="body">
                        {(this.state.attr.unauthorized) ?
                            <div className="login-block">
                                <div className="text-center p-3 p-lg-5">
                                    <i className="fa fa-lock fa-3x mb-3"></i>
                                    <h3 className="mb-3">Unauthorized.</h3>
                                </div>
                            </div> : this.props.children
                        }
                    </div>
                </div>
                <div id="modal-root"></div>
            </div>
        )
    }
}

MainLayout.defaultProps = {
    active: "registers",
    authorized: ""
}

export default withRouter(MainLayout)

