import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import { reorder } from 'react-reorder'
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import ConfirmModal from '../components/confirm-modal'
import FormModal from '../components/form-modal'
import HomeUI from "./home-ui"

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading_info: true,
                loading_banner: true,
                loading_card: true
            },
            data: {
                home_title_th: "",
                home_title_en: "",
                home_logo: "",
                name_home_logo: "",
                old_home_logo: "",
                home_word_th: "",
                home_word_en: "",
                home_description_th: "",
                home_description_en: ""
            },
            list_banner: [],
            list_card: []
        }
    }

    handleChangeData = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeFile = (e) => {
        var data = this.state.data
        if (e.target.files[0] !== undefined) {
            var file = e.target.files[0]
            if (file.size > 5000000) {
                data.name_home_logo = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('ขนาดไฟล์จะต้องมีขนาดไม่เกิน 5 MB', 'danger')
            } else if (!file.type.match(/image\/png|image\/jpg|image\/jpeg|image\/webp/g)) {
                data.name_home_logo = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('รองรับไฟล์นามสกุล .jpg .png เท่านั้น', 'danger')
            } else {
                data.name_home_logo = e.target.files[0].name
            }
        } else {
            document.getElementById(e.target.name).value = ""
            data.name_home_logo = ""
        }
        this.setState({ data: data })
    }

    handleSaveInfo = () => {
        var valid = Valid.form("recommend-form")
        if(valid){
            var params = new FormData(document.querySelector('[id="recommend-form"]'))
            Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
            axios.post(CONS.API_INFORMATION + "/update/home", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }).then((response) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                    this.setState({ data: { ...this.state.data, old_home_logo: response.data.old_home_logo } })
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleSwitchBanner = (e, i) => {
        var list_banner = this.state.list_banner
        list_banner[i][e.target.name] = (e.target.checked) ? "1" : "0"
        this.setState({ list_banner: list_banner },()=>{
            var params = {
                id : this.state.list_banner[i].id,
                publish: this.state.list_banner[i].publish,
            }

            axios.post(CONS.API_BANNER + "/publish", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleShowBanner = () => {
        ReactDOM.render(
            <FormModal
                title="เพิ่มสไลด์แบนเนอร์"
                data={{
                    image: "",
                    old_image: "",
                    name_image: "",
                    image_mobile: "",
                    name_image_mobile: "",
                    old_image_mobile: "",
                    title_th : "",
                    title_en : "",
                    word_th : "",
                    word_en : "",
                    link : "",
                }}
                formInput={[
                    { type: "img", title: "รูปภาพ (Desktop)", name: "image", require: true, recommend : "แนะนำขนาดรูปภาพ 1280x500px รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB" },
                    { type: "img", title: "รูปภาพ (Mobile)", name: "image_mobile", require: true, recommend : "แนะนำขนาดรูปภาพ 500x400px รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB" },
                    { type: "text", title: "ข้อความบรรทัด 1 (TH)", name: "title_th", require: false },
                    { type: "text", title: "ข้อความบรรทัด 1 (EN)", name: "title_en", require: false },
                    { type: "text", title: "ข้อความบรรทัด 2 (TH)", name: "word_th", require: false },
                    { type: "text", title: "ข้อความบรรทัด 2 (EN)", name: "word_en", require: false },
                    { type: "text", title: "ลิงค์ URL", name: "link", require: false },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_BANNER + "/create", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            if (response.data.banner.length > 0) {
                                var list_banner = this.state.list_banner
                                list_banner.unshift(response.data.banner[0])
                                this.setState({ list_banner: list_banner })
                            }
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleUpdateBanner = (i) =>{
        ReactDOM.render(
            <FormModal
                title="แก้ไขสไลด์แบนเนอร์"
                data={{
                    id: this.state.list_banner[i].id,
                    image: this.state.list_banner[i].image,
                    old_image: this.state.list_banner[i].old_image,
                    name_image: this.state.list_banner[i].name_image,
                    image_mobile: this.state.list_banner[i].image_mobile,
                    name_image_mobile: this.state.list_banner[i].name_image_mobile,
                    old_image_mobile: this.state.list_banner[i].old_image_mobile,
                    title_th : this.state.list_banner[i].title_th,
                    title_en : this.state.list_banner[i].title_en,
                    word_th : this.state.list_banner[i].word_th,
                    word_en : this.state.list_banner[i].word_en,
                    link : this.state.list_banner[i].link,
                    publish : this.state.list_banner[i].publish
                }}
                formInput={[
                    { type: "hidden", name: "id", require: true },
                    { type: "hidden", name: "publish", require: true },
                    { type: "img", title: "รูปภาพ", name: "image", require: false, recommend : "แนะนำขนาดรูปภาพ 1280x500px รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB" },
                    { type: "img", title: "รูปภาพ (Mobile)", name: "image_mobile", require: false, recommend : "แนะนำขนาดรูปภาพ 500x400px รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB" },
                    { type: "text", title: "ข้อความบรรทัด 1 (TH)", name: "title_th", require: false },
                    { type: "text", title: "ข้อความบรรทัด 1 (EN)", name: "title_en", require: false },
                    { type: "text", title: "ข้อความบรรทัด 2 (TH)", name: "word_th", require: false },
                    { type: "text", title: "ข้อความบรรทัด 2 (EN)", name: "word_en", require: false },
                    { type: "text", title: "ลิงค์ URL", name: "link", require: false },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_BANNER + "/update", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            var list_banner = this.state.list_banner
                            list_banner[i] = response.data.banner
                            this.setState({ list_banner: list_banner })
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleReorderBanner = (event, previousIndex, nextIndex, fromId, toId) => {
        this.setState({ list_banner: reorder(this.state.list_banner, previousIndex, nextIndex) }, () => {
            var params = {
                list: []
            }

            this.state.list_banner.map((item, i) => {
                params.list.push({
                    id: item.id,
                    order_by: i + 1
                })
                return true
            })

            axios.post(CONS.API_BANNER + "/orderby", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleDeteleBanner = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_BANNER + '/delete', {
                        id : this.state.list_banner[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list_banner = this.state.list_banner
                            list_banner.splice(index, 1)
                            this.setState({list_banner : list_banner})
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if(error.response.status === 401){
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleShowCard = () => {
        ReactDOM.render(
            <FormModal
                title="เพิ่มการ์ดเพิ่มเติม"
                data={{
                    image: "",
                    old_image: "",
                    name_image: "",
                    title_th : "",
                    title_en : "",
                    description_th : "",
                    description_en : "",
                }}
                formInput={[
                    { type: "img", title: "รูปภาพ", name: "image", require: true },
                    { type: "text", title: "หัวข้อ (TH)", name: "title_th", require: true },
                    { type: "text", title: "หัวข้อ (EN)", name: "title_en", require: true },
                    { type: "textarea", title: "รายละเอียด (TH)", name: "description_th", require: true },
                    { type: "textarea", title: "รายละเอียด (EN)", name: "description_en", require: true }
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_CARD + "/create", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            if (response.data.card.length > 0) {
                                var list_card = this.state.list_card
                                list_card.unshift(response.data.card[0])
                                this.setState({ list_card: list_card })
                            }
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleUpdateCard = (i) =>{
        ReactDOM.render(
            <FormModal
                title="แก้ไขการ์ดเพิ่มเติม"
                data={{
                    id: this.state.list_card[i].id,
                    image: this.state.list_card[i].image,
                    old_image: this.state.list_card[i].old_image,
                    name_image: this.state.list_card[i].name_image,
                    title_th : this.state.list_card[i].title_th,
                    title_en : this.state.list_card[i].title_en,
                    description_th : this.state.list_card[i].description_th,
                    description_en : this.state.list_card[i].description_en,
                }}
                formInput={[
                    { type: "hidden", name: "id", require: true },
                    { type: "img", title: "รูปภาพ", name: "image", require: false },
                    { type: "text", title: "หัวข้อ (TH)", name: "title_th", require: true },
                    { type: "text", title: "หัวข้อ (EN)", name: "title_en", require: true },
                    { type: "textarea", title: "รายละเอียด (TH)", name: "description_th", require: true },
                    { type: "textarea", title: "รายละเอียด (EN)", name: "description_en", require: true }
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_CARD + "/update", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            var list_card = this.state.list_card
                            list_card[i] = response.data.card
                            this.setState({ list_card: list_card })
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleReorderCard = (event, previousIndex, nextIndex, fromId, toId) => {
        this.setState({ list_card: reorder(this.state.list_card, previousIndex, nextIndex) }, () => {
            var params = {
                list: []
            }

            this.state.list_card.map((item, i) => {
                params.list.push({
                    id: item.id,
                    order_by: i + 1
                })
                return true
            })

            axios.post(CONS.API_CARD + "/orderby", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleDeteleCard = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_CARD + '/delete', {
                        id : this.state.list_card[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list_card = this.state.list_card
                            list_card.splice(index, 1)
                            this.setState({list_card : list_card})
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if(error.response.status === 401){
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    loadData = () => {
        axios.post(CONS.API_INFORMATION + "/get", {
            type: "home"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_info: false }, data: response.data.information })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadBanner = () => {
        axios.post(CONS.API_BANNER + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_banner: false }, list_banner: response.data.list })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadCard = () => {
        axios.post(CONS.API_CARD + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_card: false }, list_card: response.data.list })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, () => {
            this.loadData()
            this.loadBanner()
            this.loadCard()
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="home" authorized="home" onCheckToken={this.checkToken}>
                <HomeUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(Home)

