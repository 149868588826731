import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Editor from '../components/editer'

class CookieUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">นโยบายคุ๊กกี้</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <div className="card">
                        <div className="card-body">
                            <form id="submit-form">
                                <div className="row">
                                    <div className="col-12 col-lg-6 form-group">
                                        <label className="bold">เนื้อหา (TH)</label>
                                        <Editor data={data.cookie_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "cookie_th" })} disabled={attr.disabled} />
                                        <input type="hidden" id="cookie_th" name="cookie_th" value={data.cookie_th} required />  
                                    </div>
                                    <div className="col-12 col-lg-6 form-group">
                                        <label className="bold">เนื้อหา (EN)</label>
                                        <Editor data={data.cookie_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "cookie_en" })} disabled={attr.disabled} />
                                        <input type="hidden" id="cookie_en" name="cookie_en" value={data.cookie_en} required />
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="btn btn-success" onClick={this.props.handleSave}>บันทึก</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(CookieUI)

