import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import { reorder } from 'react-reorder'
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import MainLayout from "../components/main-layout"
import ConfirmModal from '../components/confirm-modal'
import ServicesUI from "./services-ui"

class Services extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
            },
            list: []
        }
    }

    handleChange = (e, i) => {
        var list = this.state.list
        list[i][e.target.name] = (e.target.checked) ? "1" : "0"
        this.setState({ list: list },()=>{
            var params = {
                id : this.state.list[i].id,
                publish: this.state.list[i].publish,
            }

            axios.post(CONS.API_SERVICE + "/publish", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        this.setState({ list: reorder(this.state.list, previousIndex, nextIndex) }, () => {
            var params = {
                list: []
            }

            this.state.list.map((item, i) => {
                params.list.push({
                    id: item.id,
                    order_by: i + 1
                })
                return true
            })
 
            axios.post(CONS.API_SERVICE + "/orderby", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleDetele = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_SERVICE + '/delete', {
                        id : this.state.list[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list = this.state.list
                            list.splice(index, 1)
                            this.setState({list : list})
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            // localStorage.removeItem('login_token')
                            // this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if(error.response.status === 401){
                            // localStorage.removeItem('login_token')
                            // this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    loadList = () => {
        axios.post(CONS.API_SERVICE + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
               this.setState({list : response.data.list})
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, this.loadList)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="services" authorized="services" onCheckToken={this.checkToken}>
                <ServicesUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(Services)
