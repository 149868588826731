import React, { Fragment } from 'react'
import { withRouter, Link } from "react-router-dom"
import Reorder from 'react-reorder'
import CONS from "../constants"

class BranchUI extends React.Component {
    render() {
        return (
            <Fragment>
                <p className="text-title">สาขา</p>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <Link className="btn btn-primary" to={CONS.URL_LINK + "/branch-add"}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</Link>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="w-60px">ลำดับ</th>
                                        <th scope="col" className="w-60px"></th>
                                        <th scope="col" className="w-100px">รูปปก</th>
                                        <th scope="col" className="w-auto-divide-2">ชื่อสาขา (TH)</th>
                                        <th scope="col" className="w-auto-divide-2">ชื่อสาขา (EN)</th>
                                        <th scope="col" className="w-100px text-center">แสดงผล</th>
                                        <th scope="col" className="w-160px text-center">ตัวเลือก</th>
                                    </tr>
                                </thead>
                                <Reorder reorderId="my-list" component="tbody" onReorder={this.props.handleReorder} draggedClassName="d-flex shadow tr-td-bottom-0 align-items-center bg-white"
                                    placeholder={
                                        <tr className="bg-gray">
                                            <td height="63px" colSpan="6"></td>
                                        </tr>
                                    }>
                                    {this.props.state.list.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="w-60px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{i + 1}</td>
                                                <td className="w-60px"><i className="fa fa-bars pointer fa-2x"></i></td>
                                                <td className="w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                    {(item.old_image) ? <img src={item.old_image} alt="images" height="40px" /> : null}
                                                </td>
                                                <td className="w-auto-divide-2" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{item.title_th}</td>
                                                <td className="w-auto-divide-2" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{item.title_en}</td>
                                                <td className="text-center w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                    <div className="custom-control custom-switch pointer">
                                                        <input type="checkbox" className="custom-control-input" name="publish" id={`publish_${i}`} checked={(item.publish === "1" ? true : false)} onChange={(e) => this.props.handleChange(e, i)} />
                                                        <label className="custom-control-label pointer" htmlFor={`publish_${i}`}></label>
                                                    </div>
                                                </td>
                                                <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                    <Link className="btn btn-warning mr-2" to={CONS.URL_LINK + "/branch-edit/" + item.id}>แก้ไข</Link>
                                                    <button type="button" className="btn btn-danger" onClick={() => this.props.handleDetele(i)}>ลบ</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Reorder>
                            </table>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(BranchUI)

