var Valid = {
    form: (formId) => {
        var count = 0
        var elements = document.getElementById(formId).querySelectorAll("[required]")

        Valid.clear(formId)

        for (var i = 0, len = elements.length; i < len; ++i) {
            if (elements[i].value !== '') continue;
            var span = document.createElement('span'),
                msg = elements[i].getAttribute('data-msg')
            if (msg == null) msg = 'กรุณาระบุ'
            span.className = 'invalid-feedback'
            span.id = "required-"+elements[i].name
            span.innerHTML = msg
            elements[i].classList.add("is-invalid")
            elements[i].parentElement.appendChild(span)
            count++
        }

        var results = (count > 0) ? false : true

        return results
    },

    clear: (formId = null) => {
        if(formId != null){
            var elements = document.getElementById(formId).querySelectorAll("[required]")
            for (var s = 0, lens = elements.length; s < lens; ++s) {
                elements[s].classList.remove("is-invalid")
            }
        }
        
        var block = document.getElementsByClassName("invalid-feedback")
        for (var i = 0, len = block.length; i < len; i++) {
            block[0].remove()
        }

        var show = document.getElementsByClassName("required-field")
        for (var f = 0, lenf = show.length; f < lenf; f++) {
            show[0].remove()
        }
    },

    show: (name, msg) => {
        var element = document.getElementsByName(name)[0]
        if(element !== undefined){
            var span = document.createElement('span')
            if (msg == null) msg = 'กรุณาระบุ'
            if(!element.className.match(/border-danger/g)){
                element.classList.add("border-danger")
            }
            if(element.nextSibling != null){
                element.nextSibling.remove()
            }
            span.className = 'help-block required-field text-danger small'
            span.innerHTML = msg
            element.parentElement.appendChild(span)
        }
    }

}

module.exports = Valid
