import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"

class CallActionUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var list = this.props.state.list

        return (
            <Fragment>
                <p className="text-title">ตั้งค่า Call-to-action</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <div className="card">
                        <div className="card-body">
                            <form id="submit-form">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="70px"></th>
                                                <th scope="col">ข้อความ</th>
                                                <th scope="col">ข้อความ (EN)</th>
                                                <th scope="col">ลิงค์</th>
                                                <th scope="col" width="100px" className="text-center">แสดงผล</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            <img src={item.image} alt="images" height="40px" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" name="title_th" value={item.title_th} onChange={(e) => this.props.handleChange(e, i)} required />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" name="title_en" value={item.title_en} onChange={(e) => this.props.handleChange(e, i)} required />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" name="link" value={item.link} onChange={(e) => this.props.handleChange(e, i)} required />
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="custom-control custom-switch pointer">
                                                                <input type="checkbox" className="custom-control-input" name="publish" id={`publish_${i}`} checked={(item.publish === "1" ? true : false)} onChange={(e) => this.props.handleChange(e, i)} />
                                                                <label className="custom-control-label pointer" htmlFor={`publish_${i}`}></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-success" onClick={this.props.handleSave}>บันทึก</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(CallActionUI)

