import React from 'react'
import ReactDOM from 'react-dom'
import Valid from '../functions/validation'
import Alert from "../functions/alert"
import Editor from './editer'

class FormModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeEditor = (e) => {
        this.setState({ data: { ...this.state.data, [e.name]: e.value } })
    }

    handleChangeFile = (e) => {
        var data = this.state.data
        if (e.target.files[0] !== undefined) {
            var file = e.target.files[0]
            if (file.size > 5000000) {
                data[`name_${e.target.name}`] = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('ขนาดไฟล์จะต้องมีขนาดไม่เกิน 5 MB', 'danger')
            } else if (!file.type.match(/image\/png|image\/jpg|image\/jpeg|image\/webp/g)) {
                data[`name_${e.target.name}`] = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('รองรับไฟล์นามสกุล .jpg .png เท่านั้น', 'danger')
            } else {
                data[`name_${e.target.name}`] = e.target.files[0].name
            }
        } else {
            data[`name_${e.target.name}`] = ""
        }
        this.setState({ data: data })
    }

    handleConfirm = () => {
        var valid = Valid.form("form-data")
        if (valid) {
            var data = (this.props.method === "json") ? this.state.data : new FormData(document.querySelector('[id="form-data"]'))
            var modal = document.getElementById(this.props.id)
            modal.classList.remove(`in`)
            modal.classList.remove(`show`)
            var backdrop = document.getElementById(`modal-backdrop`)
            backdrop.classList.remove(`show`)
            document.body.classList.remove(`modal-open`)
            setTimeout(() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                backdrop.parentNode.removeChild(backdrop)
                if (this.props.callBack !== undefined) this.props.callBack(data)
            }, 300)

        }
    }

    handleClose = () => {
        var modal = document.getElementById(this.props.id)
        modal.classList.remove(`in`)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    componentDidMount() {
        document.body.className = "modal-open"
        var backdrop = document.createElement("div")
        backdrop.id = "modal-backdrop"
        backdrop.className = "modal-backdrop fade"
        document.body.appendChild(backdrop)
        document.getElementById(this.props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(this.props.id).classList.add("show")
            document.getElementById(this.props.id).classList.add("in")
            backdrop.classList.add("show")
        }, 200)
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >{this.props.title}</h5>
                            <button type="button" className="close" onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="form-data">
                                {this.props.formInput.map((input, i) => {
                                    if (input.type === "img") {
                                        return (
                                            <div className="form-group" key={i}>
                                                <label className="bold">{input.title}{(input.require ? " *" : "")}</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id={input.name} name={input.name} onChange={this.handleChangeFile} required={input.require} />
                                                    <label className="custom-file-label" htmlFor={input.name}>{this.state.data[`name_${input.name}`]}</label>
                                                    <input type="hidden" id={`old_${input.name}`} name={`old_${input.name}`} defaultValue={this.state.data[`old_${input.name}`]} />
                                                </div>
                                                <small className="form-text text-muted">{(input.recommend !== "" && input.recommend !== undefined) ? input.recommend : "รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB"}</small>
                                                {(this.state.data[`old_${input.name}`] !== "" && this.state.data[`old_${input.name}`] !== undefined) ? <img src={this.state.data[`old_${input.name}`]} alt="images" className={(input.classImg === undefined) ? "w-100 max-w-400px" : input.classImg} /> : null}
                                            </div>
                                        )
                                    } else if (input.type === "text") {
                                        return (
                                            <div className="form-group" key={i}>
                                                <label className="bold">{input.title}{(input.require ? " *" : "")}</label>
                                                <input type="text" className="form-control" name={input.name} value={this.state.data[input.name]} onChange={this.handleChange} required={input.require} />
                                            </div>
                                        )
                                    } else if (input.type === "textarea") {
                                        return (
                                            <div className="form-group" key={i}>
                                                <label className="bold">{input.title}{(input.require ? " *" : "")}</label>
                                                <textarea className="form-control" rows="5" name={input.name} value={this.state.data[input.name]} onChange={this.handleChange} required={input.require}></textarea>
                                            </div>
                                        )
                                    } else if (input.type === "hidden") {
                                        return (
                                            <div className="d-none" key={i}>
                                                <input type="hidden" name={input.name} value={this.state.data[input.name]} onChange={this.handleChange} required={input.require} />
                                            </div>
                                        )
                                    } else if (input.type === "editor") {
                                        return (
                                            <div className="form-group" key={i}>
                                                <label className="bold">{input.title}{(input.require ? " *" : "")}</label>
                                                <Editor data={this.state.data[input.name]} onChange={(e, editor) => this.handleChangeEditor({ value: editor.getData(), name: input.name })} uploadUrl={input.uploadUrl} />
                                                <input type="hidden" id={input.name} name={input.name} value={this.state.data[input.name]} required />
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </form>
                        </div>
                        <div className="modal-footer">
                            {(this.props.displayCancel) ? <button type="button" className={this.props.btnCancelClass} onClick={this.handleClose}>{this.props.textCancel}</button> : null}
                            {(this.props.displayConfirm) ? <button type="button" className={this.props.btnConfirmClass} onClick={this.handleConfirm}>{this.props.textConfirm}</button> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormModal.defaultProps = {
    id: "formModal",
    displayConfirm: true,
    displayCancel: true,
    title: "ข้อมูล",
    textConfirm: "บันทึก",
    textCancel: "ยกเลิก",
    btnConfirmClass: "btn btn-primary",
    btnCancelClass: "btn btn-secondary",
    method: "post",
    formInput: []
}

export default FormModal