import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import MainLayout from "../components/main-layout"
import ConfirmModal from '../components/confirm-modal'
import ContentUI from "./content-ui"

class Content extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
            },
            list: []
        }
    }

    handleDetele = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_CONTENT + '/delete', {
                        id : this.state.list[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list = this.state.list
                            list.splice(index, 1)
                            this.setState({list : list})
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if(error.response.status === 401){
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    loadList = () => {
        axios.post(CONS.API_CONTENT + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
               this.setState({list : response.data.list})
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, this.loadList)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="content" authorized="content" onCheckToken={this.checkToken}>
                <ContentUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(Content)

