import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"

class SeoUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data
        var title = ""
        switch (this.props.type) {
            case "knowus": title = "รู้จักเรา"; break;
            case "service": title = "บริการ"; break;
            case "servicecharge": title = "ค่าบริการ"; break;
            case "gallery": title = "แกลลอรี่"; break;
            case "faq": title = "คำถามที่พบบ่อย"; break;
            case "content": title = "บทความ"; break;
            case "contactus": title = "ติดต่อเรา"; break;
            case "news": title = "ข่าวสารกิจกรรม"; break;
            default: title = "หน้าแรก"
        }

        return (
            <Fragment>
                <p className="text-title">ตั้งค่า SEO ({title})</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    [
                        <div key={0} className="card mb-3 mb-5">
                            <div className="card-body">
                                <h5 className="px-3 mb-4">กำหนดค่าสำหรับการค้นหาใน Google</h5>
                                <form id="meta-form">
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="title" className="bold">title tag</label>
                                        <input type="text" name="title" id="title" className="form-control" value={data.title} onChange={this.props.handleChange} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="description" className="bold">description tag</label>
                                        <input type="text" name="description" id="description" className="form-control" value={data.description} onChange={this.props.handleChange} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="keyword" className="bold">keyword tag</label>
                                        <input type="text" name="keyword" id="keyword" className="form-control" value={data.keyword} onChange={this.props.handleChange} />
                                        <small className="form-text text-muted">พิมพ์ keyword ที่ต้องการแล้วตามด้วยสัญลักษณ์ ,</small>
                                    </div>
                                    <div className="col-12">
                                        <input type="hidden" name="type" defaultValue={this.props.type} />
                                        <button type="button" className="btn btn-success" onClick={this.props.handleSaveMeta}>บันทึก</button>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div key={1} className="card">
                            <div className="card-body">
                                <h5 className="px-3 mb-4">กำหนดค่าสำหรับแชร์ไปยัง Facebook</h5>
                                <form id="og-form">
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="og_title" className="bold">og:title</label>
                                        <input type="text" name="og_title" id="og_title" className="form-control" value={data.og_title} onChange={this.props.handleChange} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="og_description" className="bold">og:description</label>
                                        <input type="text" name="og_description" id="og_description" className="form-control" value={data.og_description} onChange={this.props.handleChange} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="og_url" className="bold">og:url</label>
                                        <input type="text" name="og_url" id="og_url" className="form-control" value={data.og_url} onChange={this.props.handleChange} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label htmlFor="og_type" className="bold">og:type</label>
                                        <input type="text" name="og_type" id="og_type" className="form-control" value={data.og_type} onChange={this.props.handleChange} />
                                    </div>

                                    <div className="col-12 col-md-8 col-lg-6 form-group">
                                        <label className="bold">og:image</label>
                                        <small className="form-text text-muted mb-2">รูปนี้จะปรากฏเมื่อแชร์หน้าแรกของเว็บไซต์ไปยัง facebook</small>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="og_image" name="og_image" onChange={this.props.handleChangeFile} />
                                            <label className="custom-file-label" htmlFor="og_image">{data.og_image_name}</label>
                                            <input type="hidden" id="og_image_old" name="og_image_old" defaultValue={data.og_image_old} />
                                        </div>
                                        <small className="form-text text-muted">รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB</small>
                                        {(data.og_image_old !== "" && data.og_image_old !== undefined) ? <img src={data.og_image_old} alt="thumbnail" className="w-100 max-w-400px" /> : null}
                                    </div>
                                    <div className="col-12">
                                        <input type="hidden" name="type" defaultValue={this.props.type} />
                                        <button type="button" className="btn btn-success" onClick={this.props.handleSaveOg}>บันทึก</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ]
                }
            </Fragment>
        )
    }
}

export default withRouter(SeoUI)

