import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Reorder from 'react-reorder'

class HomeUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">หน้าแรก</p>
                {(attr.loading_info || attr.loading_banner || attr.loading_card) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    [
                        <div className="card mb-4" key={0}>
                            <div className="card-body">
                                <h6 className="form-group">สไลด์แบนเนอร์</h6>
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.props.handleShowBanner}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-60px">ลำดับ</th>
                                                <th scope="col" className="w-60px"></th>
                                                <th scope="col" className="w-100px">รูปภาพ</th>
                                                <th scope="col" className="w-auto-divide-2">ข้อความ (TH)</th>
                                                <th scope="col" className="w-auto-divide-2">ข้อความ (EN)</th>
                                                <th scope="col" className="w-100px">แสดงผล</th>
                                                <th scope="col" className="text-center w-160px">ตัวเลือก</th>
                                            </tr>
                                        </thead>
                                        <Reorder reorderId="my-list" component="tbody" onReorder={this.props.handleReorderBanner} draggedClassName="d-flex shadow tr-td-bottom-0 align-items-center bg-white"
                                            placeholder={
                                                <tr className="bg-gray">
                                                    <td height="75px" colSpan="7"></td>
                                                </tr>
                                            }>
                                            {this.props.state.list_banner.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="w-60px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{i + 1}</td>
                                                        <td className="w-60px"><i className="fa fa-bars pointer fa-2x"></i></td>
                                                        <td className="w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <img src={item.old_image} alt="thumbnail" height="50px" />
                                                        </td>
                                                        <td className="w-auto-divide-2" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            {(item.title_th === "" && item.word_th === "") ? "-" : [
                                                                <p key={0}>{item.title_th}</p>,
                                                                <p key={1}>{item.word_th}</p>
                                                            ]}
                                                        </td>
                                                        <td className="w-auto-divide-2" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            {(item.title_en === "" && item.word_en === "") ? "-" : [
                                                                <p key={0}>{item.title_en}</p>,
                                                                <p key={1}>{item.word_en}</p>
                                                            ]}
                                                        </td>
                                                        <td className="text-center w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <div className="custom-control custom-switch pointer">
                                                                <input type="checkbox" className="custom-control-input" name="publish" id={`publish_${i}`} checked={(item.publish === "1" ? true : false)} onChange={(e) => this.props.handleSwitchBanner(e, i)} />
                                                                <label className="custom-control-label pointer" htmlFor={`publish_${i}`}></label>
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <button className="btn btn-warning mr-2" onClick={() => this.props.handleUpdateBanner(i)}>แก้ไข</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => this.props.handleDeteleBanner(i)}>ลบ</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </Reorder>
                                    </table>
                                </div>
                            </div>
                        </div>,
                        <div className="card mb-4" key={1}>
                            <div className="card-body">
                                <h6 className="form-group">แนะนำ</h6>
                                <form id="recommend-form">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">รูปภาพประกอบ</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="home_logo" name="home_logo" onChange={this.props.handleChangeFile} required={(data.old_home_logo !== "" && data.old_home_logo !== undefined) ? false : true} />
                                                <label className="custom-file-label" htmlFor="home_logo">{data.name_home_logo}</label>
                                                <input type="hidden" id="old_home_logo" name="old_home_logo" defaultValue={data.old_home_logo} />
                                            </div>
                                            <small className="form-text text-muted">รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB</small>
                                            {(data.old_home_logo !== "" && data.old_home_logo !== undefined) ? <img src={data.old_home_logo} alt="thumbnail" className="w-100 max-w-400px" /> : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 1 (TH)</label>
                                            <input type="text" className="form-control" name="home_title_th" value={data.home_title_th} onChange={this.props.handleChangeData} />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 1 (EN)</label>
                                            <input type="text" className="form-control" name="home_title_en" value={data.home_title_en} onChange={this.props.handleChangeData} />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 2 (TH)</label>
                                            <input type="text" className="form-control" name="home_word_th" value={data.home_word_th} onChange={this.props.handleChangeData} />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 2 (EN)</label>
                                            <input type="text" className="form-control" name="home_word_en" value={data.home_word_en} onChange={this.props.handleChangeData} />
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 3 (TH)</label>
                                            <textarea className="form-control" name="home_description_th" rows={5} value={data.home_description_th} onChange={this.props.handleChangeData}></textarea>
                                        </div>
                                        <div className="col-12 col-lg-6 form-group">
                                            <label className="bold">บรรทัด 3 (EN)</label>
                                            <textarea className="form-control" name="home_description_en" rows={5} value={data.home_description_en} onChange={this.props.handleChangeData}></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button type="button" className="btn btn-success" onClick={this.props.handleSaveInfo}>บันทึก</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        <div className="card" key={3}>
                            <div className="card-body">
                                <h6 className="form-group">เพิ่มเติม</h6>
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.props.handleShowCard}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-60px">ลำดับ</th>
                                                <th scope="col" className="w-60px"></th>
                                                <th scope="col" className="w-100px">รูปภาพ</th>
                                                <th scope="col" className="w-auto-divide-team">ข้อความ (TH)</th>
                                                <th scope="col" className="w-auto-divide-team">ข้อความ (EN)</th>
                                                <th scope="col" className="text-center w-160px">ตัวเลือก</th>
                                            </tr>
                                        </thead>
                                        <Reorder reorderId="my-list-2" component="tbody" onReorder={this.props.handleReorderCard} draggedClassName="d-flex shadow tr-td-bottom-0 align-items-center bg-white"
                                            placeholder={
                                                <tr className="bg-gray">
                                                    <td height="75px" colSpan="7"></td>
                                                </tr>
                                            }>
                                            {this.props.state.list_card.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="w-60px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>{i + 1}</td>
                                                        <td className="w-60px"><i className="fa fa-bars pointer fa-2x"></i></td>
                                                        <td className="w-100px" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <img src={item.old_image} alt="thumbnail" height="50px" />
                                                        </td>
                                                        <td className="w-auto-divide-team" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.title_th}</p>
                                                        </td>
                                                        <td className="w-auto-divide-team" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <p>{item.title_en}</p>
                                                        </td>
                                                        <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                            <button className="btn btn-warning mr-2" onClick={() => this.props.handleUpdateCard(i)}>แก้ไข</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => this.props.handleDeteleCard(i)}>ลบ</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </Reorder>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ]
                }
            </Fragment>
        )
    }
}

export default withRouter(HomeUI)

