import React from 'react'
import ReactDOM from 'react-dom'

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleConfirm = () => {
        var modal = document.getElementById(this.props.id)
        modal.classList.remove(`in`)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
            if (this.props.callBack !== undefined) this.props.callBack()
        }, 300)
    }

    handleClose = () => {
        if (this.props.callBackCancel !== undefined) this.props.callBackCancel()
        var modal = document.getElementById(this.props.id)
        modal.classList.remove(`in`)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    componentDidMount() {
        document.body.className = "modal-open"
        var backdrop = document.createElement("div")
        backdrop.id = "modal-backdrop"
        backdrop.className = "modal-backdrop fade"
        document.body.appendChild(backdrop)
        document.getElementById(this.props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(this.props.id).classList.add("show")
            document.getElementById(this.props.id).classList.add("in")
            backdrop.classList.add("show")
        }, 200)
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className={"modal-dialog "+this.props.size} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >
                                {(!this.props.iconDisplay) ? null : this.props.iconDisplay}
                                {this.props.title}
                            </h5>
                            <button type="button" className="close" onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {(this.props.description !== "") ?
                            <div className="modal-body">
                                <p className="my-2">{this.props.description}</p>
                            </div> : null
                        }
                        <div className="modal-footer">
                            {(this.props.displayCancel) ? <button type="button" className={this.props.btnCancelClass} onClick={this.handleClose}>{this.props.textCancel}</button> : null}
                            {(this.props.displayConfirm) ? <button type="button" className={this.props.btnConfirmClass} onClick={this.handleConfirm}>{this.props.textConfirm}</button> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ConfirmModal.defaultProps = {
    id: "confirmModal",
    displayConfirm: true,
    displayCancel: true,
    callBackCancel: undefined,
    title: "Confirm Deletion",
    description: "",
    textConfirm: "Confirm",
    textCancel: "Cancel",
    btnConfirmClass: "btn btn-danger",
    btnCancelClass: "btn btn-dusky",
    iconDisplay: false,
    size: "modal-lg"
}

export default ConfirmModal