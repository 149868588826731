import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import 'rsuite/dist/styles/rsuite-default.css'
import { DateRangePicker } from 'rsuite'
import dateFns from 'date-fns'
// import CONS from "../constants"

class RegistersUI extends React.Component {
    render() {
        var state = this.props.state

        var items = state.list

        var line = 1
        var pageInation = []
        var pageNum = Math.ceil(items.length / state.attr.max_row)

        for (var x = parseInt(state.attr.offset) - 4; x <= pageNum; x++) {
            if (x <= 0) continue
            if (pageInation.length > 3 && (x - parseInt(state.attr.offset)) < 2 && x < pageNum) pageInation.shift()
            if (pageInation.length >= 5) break
            pageInation.push({ offset: x })
        }

        return (
            <Fragment>
                <p className="text-title">รายชื่อผู้ลงทะเบียน</p>
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 mb-3">
                                <DateRangePicker format="DD/MM/YYYY" onChange={this.props.handleChangeDate} value={state.date} ranges={[
                                    {
                                        label: 'Today',
                                        value: [new Date(), new Date()]
                                    },
                                    {
                                        label: 'This month',
                                        value: [dateFns.startOfMonth(new Date()), new Date()]
                                    },
                                    {
                                        label: 'This year',
                                        value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
                                    }
                                ]} />
                            </div>
                            <div className="col-6 col-md mb-3">
                                <b>พบ {items.length} รายชื่อ</b>
                            </div>
                            <div className="col-6 col-md mb-3 text-right">
                                <button className="btn btn-success" onClick={this.props.exportExcel}>Export to Excel</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead className='text-nowrap'>
                                    <tr>
                                        <th scope="col">วันที่</th>
                                        <th scope="col">ชื่อ-นามสกุล</th>
                                        <th scope="col">หมายเลขโทรศัพท์</th>
                                        <th scope="col">LINE ID</th>
                                        <th scope="col">อีเมล</th>
                                        <th scope="col">สาขา</th>
                                        <th scope="col">ประเภทห้องพักที่สนใจ</th>
                                        <th scope="col">อัตราค่าบริการที่สนใจ</th>
                                        <th scope="col">ช่วงเวลาที่สะดวกให้ติดต่อกลับ</th>
                                        <th scope="col">ฝากข้อความ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, i) => {
                                        var cls = Math.ceil(line / state.attr.max_row)
                                        if (cls === state.attr.offset) {
                                            line++
                                            return (
                                                <tr key={i}>
                                                    <td>{item.created}</td>
                                                    <td>{item.fullname}</td>
                                                    <td>{item.tel}</td>
                                                    <td>{item.line}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.branch_title}</td>
                                                    <td>{item.room_title}</td>
                                                    <td>{item.service_title}</td>
                                                    <td>{item.contact_title}</td>
                                                    <td>{item.message}</td>
                                                </tr>
                                            )
                                        } else {
                                            line++
                                            return null
                                        }
                                    })}
                                </tbody>
                            </table>
                            <div className="col-12">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end">
                                        <li className={"page-item" + (state.attr.offset <= 1 ? " disabled" : "")}>
                                            <span className="page-link pointer" data-index="-1" onClick={this.props.handlePageClick}>{"< ก่อนหน้า"}</span>
                                        </li>
                                        {pageInation.map((item, i) => {
                                            return (
                                                <li className={"page-item" + (item.offset === state.attr.offset ? " active" : "")} key={i}>
                                                    <span className="page-link pointer" data-index={item.offset} onClick={this.props.handlePageClick}>{item.offset}</span>
                                                </li>
                                            )
                                        })}
                                        <li className={"page-item" + (state.attr.offset >= pageNum ? " disabled" : "")}>
                                            <span className="page-link pointer" data-index="+1" onClick={this.props.handlePageClick}>{"ถัดไป >"}</span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(RegistersUI)

