import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import CookieUI from "./cookie-ui"

class Cookie extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading: true
            },
            data: {
                cookie_th: "",
                cookie_en: "",
            }
        }
    }

    handleChangeEditor = (e) => {
        this.setState({ data: { ...this.state.data, [e.name]: e.value } })
    }

    handleSave = () => {
        var valid = Valid.form("submit-form")
        if (valid) {
            var params = this.state.data
            axios.post(CONS.API_INFORMATION + "/update/cookie", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    loadData = () => {
        axios.post(CONS.API_INFORMATION + "/get", {
            type: "cookie"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading: false }, data: response.data.information })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, () => {
            this.loadData()
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="cookie" authorized="cookie" onCheckToken={this.checkToken}>
                <CookieUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(Cookie)

