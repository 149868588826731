var Alert = {
    showMsg(msg = "", style = "success", timeOut = 3000) {
        //Create and show alert
        var div = document.createElement('div')
        div.id = "alertShowMsg";
        div.className = `alert alert-${style} alert-dismissible fade show`
        div.style.position = "fixed"
        div.style.top = "20px"
        div.style.zIndex = "1060"
        div.style.left = "50%"
        div.style.transform = "translate(-50%)"
        div.style.minWidth = (window.screen.availWidth < 576) ? "90%" : "600px"
        div.style.opacity = 1
        div.innerHTML = `${msg}<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`
        document.body.appendChild(div)

        //Timeout remove and animation
        setTimeout(function () {
            var fadeOut = setInterval(function () {
                if (div.style.opacity > 0) {
                    div.style.opacity -= 0.1
                } else {
                    if (document.getElementById('alertShowMsg') != null) {
                        div.parentNode.removeChild(div)
                    }
                    clearInterval(fadeOut)
                }
            }, 100)
        }, timeOut)
    },

    loadingScreen(msg = "Loading...") {
        var loadingScreen = document.getElementById(`loadingScreen`)
        if (loadingScreen !== null) {
            document.body.classList.remove(`overflow-hidden`)
            loadingScreen.parentNode.removeChild(loadingScreen)
        } else {
            document.body.className = "overflow-hidden"
            var div = document.createElement('div')
            div.id = "loadingScreen"
            div.innerHTML = `<div class="modal-backdrop fade show"></div>
                            <div class="position-absolute w-100 h-100" style="z-index: 2000;">
                            <div class="row mx-0 align-items-center justify-content-center h-100">
                            <div class="col-auto text-center">
                            <div class="spinner-grow text-white" role="status"><span class="sr-only">Loading...</span></div>
                            <div class="spinner-grow text-white" role="status"><span class="sr-only">Loading...</span></div>
                            <div class="spinner-grow text-white" role="status"><span class="sr-only">Loading...</span></div>
                            <div class="spinner-grow text-white" role="status"><span class="sr-only">Loading...</span></div>
                            <div class="spinner-grow text-white" role="status"><span class="sr-only">Loading...</span></div>
                            <p class="col-auto text-white">${msg}</p>
                            </div></div></div>`
            document.body.appendChild(div)
        }
    },
}

module.exports = Alert