import React from "react";
import CONS from '../constants'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';

class Editor extends React.Component {
    render() {
        return (
            <CKEditor
                editor={ClassicEditor}
                data={this.props.data}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                config={{
                    simpleUpload: {
                        uploadUrl: this.props.uploadUrl
                    },
                    plugins: [Heading, Bold, Italic, FontColor, Alignment, Link, Indent, List, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, BlockQuote, Table, TableToolbar, MediaEmbed, Essentials, Autoformat, TextTransformation, Paragraph, PasteFromOffice, SimpleUploadAdapter, HorizontalLine, LinkImage, Strikethrough, SourceEditing, HtmlEmbed],
                    alignment: {
                        options: ['left', 'center', 'right']
                    },
                    image: {
                        toolbar: ['imageTextAlternative', 'imageStyle:side', 'imageStyle:alignCenter',  'linkImage']
                    },
                    table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                    },
                    toolbar: (this.props.small ? ['bold', 'italic', 'strikethrough'] : ['heading', '|', 'bold', 'italic', 'strikethrough', 'fontColor', 'alignment', 'link', 'bulletedList', 'numberedList', 'horizontalLine', 'imageUpload', 'linkImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'HtmlEmbed', '|', 'sourceEditing', 'undo', 'redo'])
                }}
            />
        );
    }
}

Editor.defaultProps = {
    uploadUrl: CONS.API_CONTENT + "/upload",
    disabled: false,
    small: false
}
export default Editor;