var Format = {
    price(number, decimal = 0) {
        number = (number !== undefined && number !== "") ? number : 0
        number = Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: parseInt(decimal), maximumFractionDigits: parseInt(decimal), }).format(parseFloat(number))
        return number
    },

    convertDateToString(date) {
        var month = date.getMonth() + 1
        var day = date.getDate()
        var year = date.getFullYear()

        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day
        return year + "-" + month + "-" + day;
    }
}

module.exports = Format