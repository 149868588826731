import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import { reorder } from 'react-reorder'
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import ConfirmModal from '../components/confirm-modal'
import FormModal from '../components/form-modal'
import KnowUsUI from "./knowus-ui"

class KnowUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading_info: true,
                loading_team: true
            },
            data: {
                knowus_bg_word_th: "",
                knowus_bg_word_en: "",
                knowus_bg: "",
                name_knowus_bg: "",
                old_knowus_bg: "",
                knowus_title_th: "",
                knowus_title_en: "",
                knowus_description_th: "",
                knowus_description_en: ""
            },
            list: []
        }
    }

    handleChangeData = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeEditor = (e) => {
        this.setState({ data: { ...this.state.data, [e.name]: e.value } })
    }

    handleChangeFile = (e) => {
        var data = this.state.data
        if (e.target.files[0] !== undefined) {
            var file = e.target.files[0]
            if (file.size > 5000000) {
                data.name_knowus_bg = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('ขนาดไฟล์จะต้องมีขนาดไม่เกิน 5 MB', 'danger')
            } else if (!file.type.match(/image\/png|image\/jpg|image\/jpeg|image\/webp/g)) {
                data.name_knowus_bg = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('รองรับไฟล์นามสกุล .jpg .png เท่านั้น', 'danger')
            } else {
                data.name_knowus_bg = e.target.files[0].name
            }
        } else {
            document.getElementById(e.target.name).value = ""
            data.name_knowus_bg = ""
        }
        this.setState({ data: data })
    }

    handleSaveWord = () => {
        var valid = Valid.form("word-form")
        if (valid) {
            var params = {
                knowus_bg_word_th: this.state.data.knowus_bg_word_th,
                knowus_bg_word_en: this.state.data.knowus_bg_word_en,
            }
            axios.post(CONS.API_INFORMATION + "/update/knowus/bgword", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleSaveBg = () => {
        var valid = Valid.form("bg-form")
        if (valid) {
            var params = new FormData(document.querySelector('[id="bg-form"]'))
            Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
            axios.post(CONS.API_INFORMATION + "/update/knowus/background", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }).then((response) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                    this.setState({ data: { ...this.state.data, old_knowus_bg: response.data.old_knowus_bg } })
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleSaveKnowUs = () => {
        var valid = Valid.form("knowus-form")
        if (valid) {
            var params = {
                knowus_title_th: this.state.data.knowus_title_th,
                knowus_title_en: this.state.data.knowus_title_en,
                knowus_description_th: this.state.data.knowus_description_th,
                knowus_description_en: this.state.data.knowus_description_en,
            }
            axios.post(CONS.API_INFORMATION + "/update/knowus/description", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleShowAdd = () => {
        ReactDOM.render(
            <FormModal
                title="เพิ่มทีมงานของเรา"
                data={{
                    image: "",
                    old_image: "",
                    name_image: "",
                    name_th: "",
                    name_en: "",
                    position_th: "",
                    position_en: ""
                }}
                formInput={[
                    { type: "img", title: "รูปภาพ", name: "image", require: true },
                    { type: "text", title: "ชื่อ-นามสกุล (TH)", name: "name_th", require: true },
                    { type: "text", title: "ชื่อ-นามสกุล (EN)", name: "name_en", require: true },
                    { type: "editor", title: "ตำแหน่งงาน (TH)", name: "position_th", require: false, uploadUrl: CONS.API_INFORMATION + "/upload" },
                    { type: "editor", title: "ตำแหน่งงาน (EN)", name: "position_en", require: false, uploadUrl: CONS.API_INFORMATION + "/upload" },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_PERSONNEL + "/create", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            if (response.data.personnel.length > 0) {
                                var list = this.state.list
                                list.unshift(response.data.personnel[0])
                                this.setState({ list: list })
                            }
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleUpdate = (i) => {
        ReactDOM.render(
            <FormModal
                title="แก้ไขทีมงานของเรา"
                data={{
                    id: this.state.list[i].id,
                    image: this.state.list[i].image,
                    old_image: this.state.list[i].old_image,
                    name_image: this.state.list[i].name_image,
                    name_th: this.state.list[i].name_th,
                    name_en: this.state.list[i].name_en,
                    position_th: this.state.list[i].position_th,
                    position_en: this.state.list[i].position_en,
                }}
                formInput={[
                    { type: "hidden", name: "id", require: true },
                    { type: "img", title: "รูปภาพ", name: "image", require: false, classImg: "w-60px" },
                    { type: "text", title: "ชื่อ-นามสกุล (TH)", name: "name_th", require: true },
                    { type: "text", title: "ชื่อ-นามสกุล (EN)", name: "name_en", require: true },
                    { type: "editor", title: "ตำแหน่งงาน (TH)", name: "position_th", require: false, uploadUrl: CONS.API_INFORMATION + "/upload" },
                    { type: "editor", title: "ตำแหน่งงาน (EN)", name: "position_en", require: false, uploadUrl: CONS.API_INFORMATION + "/upload" },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_PERSONNEL + "/update", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            var list = this.state.list
                            list[i] = response.data.personnel
                            this.setState({ list: list })
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        this.setState({ list: reorder(this.state.list, previousIndex, nextIndex) }, () => {
            var params = {
                list: []
            }

            this.state.list.map((item, i) => {
                params.list.push({
                    id: item.id,
                    order_by: i + 1
                })
                return true
            })

            axios.post(CONS.API_PERSONNEL + "/orderby", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleDetele = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_PERSONNEL + '/delete', {
                        id: this.state.list[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list = this.state.list
                            list.splice(index, 1)
                            this.setState({ list: list })
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    loadData = () => {
        axios.post(CONS.API_INFORMATION + "/get", {
            type: "knowus"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_info: false }, data: response.data.information })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadList = () => {
        axios.post(CONS.API_PERSONNEL + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_team: false }, list: response.data.list })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, () => {
            this.loadData()
            this.loadList()
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="knowus" authorized="knowus" onCheckToken={this.checkToken}>
                <KnowUsUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(KnowUs)

