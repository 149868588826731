import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import './css/main.css';
import CONS from "./constants"
import Login from './pages/login-app'
import Registers from './pages/registers-app'
import Popup from './pages/popup-app'
import Seo from './pages/seo-app'
// import Faq from './pages/faq-app'
// import FaqAdd from './pages/faq-add-app'
// import FaqEdit from './pages/faq-edit-app'
import Content from './pages/content-app'
import ContentAdd from './pages/content-add-app'
import ContentEdit from './pages/content-edit-app'
// import Gallery from './pages/gallery-app'
import CallAction from './pages/call-action-app'
import ContactUs from './pages/contact-us-app'
import Services from './pages/services-app'
import ServicesAdd from './pages/services-add-app'
import ServicesEdit from './pages/services-edit-app'
import ServiceCharge from './pages/service-charge-app'
import KnowUs from './pages/knowus-app'
import Home from './pages/home-app'
import News from './pages/news-app'
import NewsAdd from './pages/news-add-app'
import NewsEdit from './pages/news-edit-app'
// import Staff from './pages/staff-app'
import Branch from "./pages/branch-app"
import BranchAdd from './pages/branch-add-app'
import BranchEdit from './pages/branch-edit-app'
import Cookie from "./pages/cookie-app"
import ReportProblems from "./pages/report-problem-app"

function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path={CONS.URL_LINK + "/"}>
                    <Login />
                </Route>
                <Route exact path={CONS.URL_LINK + "/login"}>
                    <Login />
                </Route>
                <Route exact path={CONS.URL_LINK + "/registers"}>
                    <Registers />
                </Route>
                <Route exact path={CONS.URL_LINK + "/popup"}>
                    <Popup />
                </Route>
                <Route exact path={CONS.URL_LINK + "/seo/:type"}>
                    <Seo />
                </Route>
                {/* <Route exact path={CONS.URL_LINK + "/faq"}>
                    <Faq/>
                </Route>
                <Route exact path={CONS.URL_LINK + "/faq-add"}>
                    <FaqAdd/>
                </Route>
                <Route exact path={CONS.URL_LINK + "/faq-edit/:id"}>
                    <FaqEdit/>
                </Route> */}
                <Route exact path={CONS.URL_LINK + "/content"}>
                    <Content />
                </Route>
                <Route exact path={CONS.URL_LINK + "/content-add"}>
                    <ContentAdd />
                </Route>
                <Route exact path={CONS.URL_LINK + "/content-edit/:id"}>
                    <ContentEdit />
                </Route>
                <Route exact path={CONS.URL_LINK + "/home"}>
                    <Home />
                </Route>
                <Route exact path={CONS.URL_LINK + "/knowus"}>
                    <KnowUs />
                </Route>
                <Route exact path={CONS.URL_LINK + "/services"}>
                    <Services />
                </Route>
                <Route exact path={CONS.URL_LINK + "/services-add"}>
                    <ServicesAdd />
                </Route>
                <Route exact path={CONS.URL_LINK + "/services-edit/:id"}>
                    <ServicesEdit />
                </Route>
                <Route exact path={CONS.URL_LINK + "/servicecharge"}>
                    <ServiceCharge />
                </Route>
                {/* <Route exact path={CONS.URL_LINK + "/gallery"}>
                    <Gallery/>
                </Route> */}
                <Route exact path={CONS.URL_LINK + "/contactus"}>
                    <ContactUs />
                </Route>
                <Route exact path={CONS.URL_LINK + "/call-action"}>
                    <CallAction />
                </Route>
                <Route exact path={CONS.URL_LINK + "/news"}>
                    <News />
                </Route>
                <Route exact path={CONS.URL_LINK + "/news-add"}>
                    <NewsAdd />
                </Route>
                <Route exact path={CONS.URL_LINK + "/news-edit/:id"}>
                    <NewsEdit />
                </Route>
                {/* <Route exact path={CONS.URL_LINK + "/staff"}>
                    <Staff/>
                </Route> */}
                <Route exact path={CONS.URL_LINK + "/branch"}>
                    <Branch />
                </Route>
                <Route exact path={CONS.URL_LINK + "/branch-add"}>
                    <BranchAdd />
                </Route>
                <Route exact path={CONS.URL_LINK + "/branch-edit/:id"}>
                    <BranchEdit />
                </Route>
                <Route exact path={CONS.URL_LINK + "/cookie"}>
                    <Cookie />
                </Route>
                <Route exact path={CONS.URL_LINK + "/report/problem"}>
                    <ReportProblems />
                </Route>
            </Switch>
        </Router>
    )
}

export default Routes