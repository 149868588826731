import React, { Fragment } from 'react'
import { withRouter, Link } from "react-router-dom"
import CONS from "../constants"

class ContentUI extends React.Component {
    render() {
        return (
            <Fragment>
                <p className="text-title">บทความ</p>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <Link className="btn btn-primary" to={CONS.URL_LINK + "/content-add"}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</Link>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">รูปปก</th>
                                        <th scope="col">หัวข้อ (TH)</th>
                                        <th scope="col">หัวข้อ (EN)</th>
                                        <th scope="col">วันที่สร้าง</th>
                                        <th scope="col">วันที่แก้ไขล่าสุด</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.state.list.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <img src={item.old_image} alt="thumbnail" height="50px" />
                                                </td>
                                                <td>{item.title_th}</td>
                                                <td>{item.title_en}</td>
                                                <td>{item.created}</td>
                                                <td>{item.updated}</td>
                                                <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                    <Link className="btn btn-warning mr-2" to={CONS.URL_LINK + "/content-edit/" + item.id}>แก้ไข</Link>
                                                    <button type="button" className="btn btn-danger" onClick={() => this.props.handleDetele(i)}>ลบ</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(ContentUI)

