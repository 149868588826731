import React, { Fragment } from 'react'
import { withRouter, Link } from "react-router-dom"
import CONS from "../constants"

class ContentUI extends React.Component {
    render() {
        var attr = this.props.state.attr

        return (
            <Fragment>
                <p className="text-title">ข่าวสารกิจกรรม</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <div className="card">
                        <div className="card-body">
                            <div className="row form-group align-items-center">
                                <div className="col-5">
                                    <Link className="btn btn-primary" to={CONS.URL_LINK + "/news-add"}><i className="fa fa-plus-circle mr-2"></i>เพิ่ม</Link>
                                </div>
                                <div className="col-7">
                                    <div className="float-right">
                                        <p className="d-inline-block align-middle mr-3">การใช้งาน</p>
                                        <div className="onoffswitch d-inline-block align-middle">
                                            <input type="checkbox" name="is_news" className="onoffswitch-checkbox" id="is_news" tabIndex="0" onChange={this.props.handleChange} checked={(this.props.state.data.is_news === "1" ? true : false)}/>
                                            <label className="onoffswitch-label mb-0" htmlFor="is_news">
                                                <span className="onoffswitch-inner"></span>
                                                <span className="onoffswitch-switch"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">รูปปก</th>
                                            <th scope="col">หัวข้อ (TH)</th>
                                            <th scope="col">สาขา</th>
                                            <th scope="col">วันที่สร้าง</th>
                                            <th scope="col">วันที่แก้ไขล่าสุด</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.state.list.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <img src={item.old_image} alt="thumbnail" height="50px" />
                                                    </td>
                                                    <td>{item.title_th}</td>
                                                    <td>{(item.branch_list.length >0) ? item.branch_list.join(", ") : "-"}</td>
                                                    <td>{item.created}</td>
                                                    <td>{item.updated}</td>
                                                    <td className="text-center w-160px text-nowarp" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                                        <Link className="btn btn-warning mr-2" to={CONS.URL_LINK + "/news-edit/" + item.id}>แก้ไข</Link>
                                                        <button type="button" className="btn btn-danger" onClick={() => this.props.handleDetele(i)}>ลบ</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(ContentUI)

