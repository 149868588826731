import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import MainLayout from "../components/main-layout"
import SeoUI from "./seo-ui"

class Seo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading: true,
            },
            data: {
                title: "",
                description: "",
                keyword: "",
                og_title: "",
                og_description : "",
                og_url : "",
                og_type : "",
                og_image : "",
                og_image_old : "",
                og_image_name : "",
            }
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeFile = (e) => {
        var data = this.state.data
        if (e.target.files[0] !== undefined) {
            var file = e.target.files[0]
            if (file.size > 5000000) {
                data.og_image_name = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('ขนาดไฟล์จะต้องมีขนาดไม่เกิน 5 MB', 'danger')
            } else if (!file.type.match(/image\/png|image\/jpg|image\/jpeg|image\/webp/g)) {
                data.og_image_name = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('รองรับไฟล์นามสกุล .jpg .png เท่านั้น', 'danger')
            } else {
                data.og_image_name = e.target.files[0].name
            }
        } else {
            data.og_image_name = ""
        }
        this.setState({ data: data })
    }

    handleSaveMeta = () => {
        var params = new FormData(document.querySelector('[id="meta-form"]'))
        axios.post(CONS.API_SEO + "/update/meta", params, {
            processData: false,
            contentType: false,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                Alert.showMsg('บันทึกสำเร็จ')
            } else {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    handleSaveOg = () => {
        var params = new FormData(document.querySelector('[id="og-form"]'))
        axios.post(CONS.API_SEO + "/update/og", params, {
            processData: false,
            contentType: false,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                Alert.showMsg('บันทึกสำเร็จ')
                this.setState({ data : {...this.state.data, og_image_old : response.data.og_image_old}})
            } else {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadData = (type) => {
        axios.post(CONS.API_SEO + "/get", {
            type: type
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
               this.setState({data : response.data.seo, attr : {...this.state.attr, loading : false}})
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, ()=>this.loadData(this.props.match.params.type))
    }

    UNSAFE_componentWillReceiveProps(netProps){
        this.loadData(netProps.match.params.type)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active={`seo/${this.props.match.params.type}`} authorized="seo" onCheckToken={this.checkToken}>
                <SeoUI {...this} type={this.props.match.params.type}/>
            </MainLayout>
        )
    }
}

export default withRouter(Seo)

