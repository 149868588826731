import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"

class PopupUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">ตั้งค่า Pop-up</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <div className="card">
                        <div className="card-body">
                            <form id="submit-form">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <div className="form-check">
                                            <input className="form-check-input check-box" type="radio" name="status" id="status_0" value="0" checked={(data.status === "0") ? true : false} onChange={this.props.handleChange} />
                                            <label className="form-check-label ml-2 bold" htmlFor="status_0">ปิดใช้งาน</label>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="col-12 form-check">
                                            <input className="form-check-input check-box" type="radio" name="status" id="status_1" value="1" checked={(data.status === "1") ? true : false} onChange={this.props.handleChange} />
                                            <label className="form-check-label ml-2 bold" htmlFor="status_1">เปิดใช้งาน</label>
                                        </div>
                                    </div>
                                    {(data.status === "1") ?
                                        [
                                            <div key={0} className="col-12 col-md-8 col-lg-6 form-group">
                                                <label htmlFor="title_th" className="bold">ชื่อหน้า (TH) *</label>
                                                <input type="text" name="title_th" id="title_th" className="form-control" value={data.title_th} onChange={this.props.handleChange} required />
                                                <small className="form-text text-muted">ชื่อหน้านี้จะปรากฏอยู่เหนือ Pop-up รูปภาพ</small>
                                            </div>,
                                            <div key={1} className="col-12 col-md-8 col-lg-6 form-group">
                                                <label htmlFor="title_en" className="bold">ชื่อหน้า (EN) *</label>
                                                <input type="text" name="title_en" id="title_en" className="form-control" value={data.title_en} onChange={this.props.handleChange} required />
                                                <small className="form-text text-muted">ชื่อหน้านี้จะปรากฏอยู่เหนือ Pop-up รูปภาพ</small>
                                            </div>,
                                            <div key={2} className="col-12 col-md-8 col-lg-6 form-group">
                                                <label className="bold">เลือกรูปภาพ *</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="image" name="image" onChange={this.props.handleChangeFile} required={(data.old_image !== "" && data.old_image !== undefined) ? false : true} />
                                                    <label className="custom-file-label" htmlFor="image">{data.name_image}</label>
                                                    <input type="hidden" id="old_image" name="old_image" defaultValue={data.old_image} />
                                                </div>
                                                <small className="form-text text-muted">รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB</small>
                                                {(data.old_image !== "" && data.old_image !== undefined) ? <img src={data.old_image} alt="thumbnail" className="w-100 max-w-400px" /> : null}
                                            </div>,
                                            <div key={3} className="col-12 col-md-8 col-lg-6 form-group">
                                                <label htmlFor="url" className="bold">ลิงค์ URL</label>
                                                <input type="text" name="url" id="url" className="form-control" value={data.url} onChange={this.props.handleChange} />
                                                <small className="form-text text-muted">เช่น http://www.yourwebsite.com</small>
                                            </div>
                                        ] : null
                                    }
                                    <div className="col-12">
                                        <button type="button" className="btn btn-success" onClick={this.props.handleSave}>บันทึก</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(PopupUI)

