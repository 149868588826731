import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import Valid from '../functions/validation'
import CONS from "../constants"
import Alert from "../functions/alert"

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                show_pass: false
            },
            data: {
                username: "",
                password: ""
            }
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleSwitch = () => {
        this.setState({ attr: { ...this.state.attr, show_pass: (this.state.attr.show_pass ? false : true) } })
    }

    handleLogin = () => {
        var valid = Valid.form("login_form")
        if (valid) {
            axios.post(CONS.API_LOGIN, {
                username: this.state.data.username,
                password: this.state.data.password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    localStorage.setItem("login_token", response.data.access_token)
                    this.props.history.push(CONS.URL_LINK + `/${response.data.redirect}`)
                } else if (response.data.status === "fail") {
                    Alert.showMsg(response.data.message, 'danger')
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="container-fluid h-100 px-0">
                <div className="card bg-black login-block">
                    <div className="card-body p-3 p-lg-5">
                        <form id="login_form" className="pb-3">
                            <div className="form-group">
                                <label>Username or Email address</label>
                                <input type="text" className="form-control" name="username" value={this.state.data.username} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <div className="input-group group-password mb-3">
                                    <input type={(this.state.attr.show_pass ? "text" : "password")} className="form-control" name="password" value={this.state.data.password} onChange={this.handleChange} required />
                                    <div className="input-group-append">
                                        <button className="btn" type="button" onClick={this.handleSwitch}>
                                            <i className={`fa ${(this.state.attr.show_pass ? "fa-eye" : "fa-eye-slash")}`}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button type="button" className="btn btn-primary btn-block mb-3" onClick={this.handleLogin}>Log In</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Login)

