import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Valid from '../functions/validation'
import Alert from "../functions/alert"
import CONS from "../constants"


class ChangePassModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                current_password: "",
                new_assword: "",
                confirm_password: "",
            }
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleConfirm = () => {
        var valid = Valid.form("change_pass_form")
        if (valid) {
            if (this.state.data.new_assword.length < 8) {
                Alert.showMsg('รหัสผ่านใหม่จะต้องมีตัวอักษรมากว่า 8 ตัวอักษร', 'danger')
                return false
            }

            if (this.state.data.new_assword !== this.state.data.confirm_password) {
                Alert.showMsg('รหัสผ่านใหม่กับยืนยันรหัสผ่านใหม่ไม่ตรงกัน', 'danger')
                return false
            }

            let matchedCase = new Array([])
            matchedCase.push("[0-9]")
            matchedCase.push("[a-z]")
            var ctr = 0;
            for (var i = 0; i < matchedCase.length; i++) {
                if (new RegExp(matchedCase[i]).test(this.state.data.new_assword)) {
                    ctr++;
                }
            }

            if (ctr !== 3) {
                Alert.showMsg('รหัสผ่านใหม่จะต้องประกอบด้วยตัวอักษร (a-z) และตัวเลข (0-9)', 'danger')
                return false
            }

            axios.post(CONS.API_STAFF + '/change-password', this.state.data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.props.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    var modal = document.getElementById(this.props.id)
                    modal.classList.remove(`in`)
                    modal.classList.remove(`show`)
                    var backdrop = document.getElementById(`modal-backdrop`)
                    backdrop.classList.remove(`show`)
                    document.body.classList.remove(`modal-open`)
                    setTimeout(() => {
                        ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                        backdrop.parentNode.removeChild(backdrop)
                        if (this.props.callBack !== undefined) this.props.callBack(this.state.data)
                    }, 300)
                } else if (response.data.status === "fail") {
                    Alert.showMsg(response.data.message, 'danger')
                }
            }).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleClose = () => {
        var modal = document.getElementById(this.props.id)
        modal.classList.remove(`in`)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    componentDidMount() {
        document.body.className = "modal-open"
        var backdrop = document.createElement("div")
        backdrop.id = "modal-backdrop"
        backdrop.className = "modal-backdrop fade"
        document.body.appendChild(backdrop)
        document.getElementById(this.props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(this.props.id).classList.add("show")
            document.getElementById(this.props.id).classList.add("in")
            backdrop.classList.add("show")
        }, 200)
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Change Password</h5>
                            <button type="button" className="close" onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="change_pass_form">
                                <div className="form-group">
                                    <label>รหัสผ่านปัจจุบัน</label>
                                    <input type="password" className="form-control" name="current_password" value={this.state.data.current_password} onChange={this.handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label>รหัสผ่านใหม่</label>
                                    <input type="password" className="form-control" name="new_assword" value={this.state.data.new_assword} onChange={this.handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label>ยืนยันรหัสผ่านใหม่</label>
                                    <input type="password" className="form-control" name="confirm_password" value={this.state.data.confirm_password} onChange={this.handleChange} required />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.handleConfirm}>ยืนยัน</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ChangePassModal.defaultProps = {
    id: "changePassModal",
    access_token: ""
}

export default ChangePassModal