import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import { reorder } from 'react-reorder'
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import ConfirmModal from '../components/confirm-modal'
import FormModal from '../components/form-modal'
import ContactUsUI from "./contact-us-ui"

class ContactUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading_info: true,
                loading_contact: true
            },
            data: {
                address_th: "",
                address_en: "",
                address_2_th: "",
                address_2_en: ""
            },
            list: []
        }
    }

    handleChangeData = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeEditor = (e) => {
        this.setState({ data: { ...this.state.data, [e.name]: e.value } })
    }

    handleSaveAddress = () => {
        var valid = Valid.form("submit-form")
        if (valid) {
            var params = this.state.data
            axios.post(CONS.API_INFORMATION + "/update/address", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    handleShowAdd = () => {
        ReactDOM.render(
            <FormModal
                title="เพิ่มช่องทางติดต่อ"
                data={{
                    image: "",
                    old_image: "",
                    name_image: "",
                    title_th : "",
                    title_en : "",
                    word_th : "",
                    word_en : "",
                    link : ""
                }}
                formInput={[
                    { type: "img", title: "รูปภาพ", name: "image", require: true },
                    { type: "text", title: "หัวข้อ (TH)", name: "title_th", require: true },
                    { type: "text", title: "หัวข้อ (EN)", name: "title_en", require: true },
                    { type: "text", title: "ข้อความ (TH)", name: "word_th", require: true },
                    { type: "text", title: "ข้อความ (EN)", name: "word_en", require: true },
                    { type: "text", title: "ลิงค์", name: "link", require: true },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_CONTACT + "/create", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            if (response.data.contact.length > 0) {
                                var list = this.state.list
                                list.unshift(response.data.contact[0])
                                this.setState({ list: list })
                            }
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleUpdate = (i) =>{
        ReactDOM.render(
            <FormModal
                title="แก้ไขช่องทางติดต่อ"
                data={{
                    id: this.state.list[i].id,
                    image: this.state.list[i].image,
                    old_image: this.state.list[i].old_image,
                    name_image: this.state.list[i].name_image,
                    title_th : this.state.list[i].title_th,
                    title_en : this.state.list[i].title_en,
                    word_th : this.state.list[i].word_th,
                    word_en : this.state.list[i].word_en,
                    link : this.state.list[i].link,
                }}
                formInput={[
                    { type: "hidden", name: "id", require: true },
                    { type: "img", title: "รูปภาพ", name: "image", require: false , classImg: "w-60px"},
                    { type: "text", title: "หัวข้อ (TH)", name: "title_th", require: true },
                    { type: "text", title: "หัวข้อ (EN)", name: "title_en", require: true },
                    { type: "text", title: "ข้อความ (TH)", name: "word_th", require: true },
                    { type: "text", title: "ข้อความ (EN)", name: "word_en", require: true },
                    { type: "text", title: "ลิงค์", name: "link", require: true },
                ]}
                callBack={(data) => {
                    Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                    axios.post(CONS.API_CONTACT + "/update", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (response.data.status === "success") {
                            Alert.showMsg("บันทึกสำเร็จ")
                            var list = this.state.list
                            list[i] = response.data.contact
                            this.setState({ list: list })
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                        if (error.response.status === 401) {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        this.setState({ list: reorder(this.state.list, previousIndex, nextIndex) }, () => {
            var params = {
                list: []
            }

            this.state.list.map((item, i) => {
                params.list.push({
                    id: item.id,
                    order_by: i + 1
                })
                return true
            })

            axios.post(CONS.API_CONTACT + "/orderby", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        })
    }

    handleDetele = (index) => {
        ReactDOM.render(
            <ConfirmModal
                title="ยืนยันการลบหรือไม่ ?"
                textConfirm="ลบ"
                displayCancel={false}
                btnConfirmClass="btn btn-danger"
                size=""
                callBack={() => {
                    axios.post(CONS.API_CONTACT + '/delete', {
                        id : this.state.list[index].id
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                        }
                    }
                    ).then((response) => {
                        if (response.data.status === "success") {
                            var list = this.state.list
                            list.splice(index, 1)
                            this.setState({list : list})
                            Alert.showMsg("ลบข้อมูลสำเร็จ")
                        } else {
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }).catch((error) => {
                        if(error.response.status === 401){
                            localStorage.removeItem('login_token')
                            this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else {
                            console.log(error)
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    loadData = () => {
        axios.post(CONS.API_INFORMATION + "/get", {
            type: "address"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_info: false }, data: response.data.information })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadList = () => {
        axios.post(CONS.API_CONTACT + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                this.setState({ attr: { ...this.state.attr, loading_contact: false }, list: response.data.list })
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, () => {
            this.loadData()
            this.loadList()
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="contactus" authorized="contactus" onCheckToken={this.checkToken}>
                <ContactUsUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(ContactUs)

