import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import BranchEntryUI from "./branch-entry-ui"

class BranchEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading: true,
                disabled: false
            },
            data: {
                id: "",
                title_th: "",
                title_en: "",
                detail_th: "",
                detail_en: "",
                image: "",
                old_image: "",
                name_image: "",
                publish: "1"
            },
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeEditor = (e) => {
        this.setState({ data: { ...this.state.data, [e.name]: e.value } })
    }

    handleChangeFile = (e) => {
        var data = this.state.data
        if (e.target.files[0] !== undefined) {
            var file = e.target.files[0]
            if (file.size > 5000000) {
                data.name_image = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('ขนาดไฟล์จะต้องมีขนาดไม่เกิน 5 MB', 'danger')
            } else if (!file.type.match(/image\/png|image\/jpg|image\/jpeg|image\/webp/g)) {
                data.name_image = ""
                document.getElementById(e.target.name).value = ""
                Alert.showMsg('รองรับไฟล์นามสกุล .jpg .png เท่านั้น', 'danger')
            } else {
                data.name_image = e.target.files[0].name
            }
        } else {
            data.name_image = ""
        }
        this.setState({ data: data })
    }

    handleSave = () => {
        var valid = Valid.form("submit-form")
        if (this.state.data.detail_th === "") Valid.show("detail_th")
        if (this.state.data.detail_en === "") Valid.show("detail_en")
        if (valid && this.state.data.detail_th !== "" && this.state.data.detail_en !== "") {
            var params = new FormData(document.querySelector('[id="submit-form"]'))
            Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
            axios.post(CONS.API_BRANCH + "/update", params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (response.data.status === "success") {
                    localStorage.setItem("alert", "บันทึกสำเร็จ")
                    this.props.history.push(CONS.URL_LINK + `/branch`)
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                Alert.loadingScreen("กำลังบันทึกข้อมูล กรุณารอสักครู่.....")
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    loadData = () => {
        axios.post(CONS.API_BRANCH + "/list", {
            id: this.props.match.params.id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                if (response.data.list.length > 0) {
                    this.setState({ data: response.data.list[0], attr: { ...this.state.attr, loading: false } })
                } else {
                    Alert.showMsg("ไม่พบข้อมูล", "danger")
                    this.setState({ attr: { ...this.state.attr, loading: false, disabled: true } })
                }
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, this.loadData)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="branch" authorized="branch" onCheckToken={this.checkToken}>
                <BranchEntryUI {...this} title="แก้ไขสาขา" />
            </MainLayout>
        )
    }
}

export default withRouter(BranchEdit)

