import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Editor from '../components/editer'
import CONS from "../constants"

class ServiceChargeUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <div className="row align-items-center">
                    <div className="col-5">
                        <p className="text-title">ค่าบริการ</p>
                    </div>
                    <div className="col-7">
                        <div className="float-right">
                            <p className="d-inline-block align-middle mr-3">การใช้งาน</p>
                            <div className="onoffswitch d-inline-block align-middle">
                                <input type="checkbox" name="is_servicecharge" className="onoffswitch-checkbox" id="is_servicecharge" tabIndex="0" onChange={this.props.handleChange} checked={(this.props.state.data.is_servicecharge === "1" ? true : false)} />
                                <label className="onoffswitch-label mb-0" htmlFor="is_servicecharge">
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <Fragment>
                        <form id="submit-form">
                            <div className="card">
                                <div className="card-header bg-white">
                                    <h5>ยูเวลแคร์ ศรีนครินทร์</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-xl-6 form-group">
                                            <label className="bold">เนื้อหา (TH)</label>
                                            <Editor data={data.service_2_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "service_2_th" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="service_2_th" name="service_2_th" value={data.service_2_th} />
                                        </div>
                                        <div className="col-12 col-xl-6 form-group">
                                            <label className="bold">เนื้อหา (EN)</label>
                                            <Editor data={data.service_2_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "service_2_en" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="service_2_en" name="service_2_en" value={data.service_2_en} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-4">
                                <div className="card-header bg-white">
                                    <h5>ยูเวลแคร์ พระราม 3</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-xl-6 form-group">
                                            <label className="bold">เนื้อหา (TH)</label>
                                            <Editor data={data.service_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "service_th" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="service_th" name="service_th" value={data.service_th} />
                                        </div>
                                        <div className="col-12 col-xl-6 form-group">
                                            <label className="bold">เนื้อหา (EN)</label>
                                            <Editor data={data.service_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "service_en" })} uploadUrl={CONS.API_INFORMATION + "/upload"} />
                                            <input type="hidden" id="service_en" name="service_en" value={data.service_en} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button type="button" className="btn btn-success" onClick={this.props.handleSave}>บันทึก</button>
                            </div>
                        </form>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(ServiceChargeUI)
