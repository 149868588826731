import React, { Fragment } from 'react'

const readOnly = {
    backgroundColor: "#e9ecef",
    opacity: "1",
    pointerEvents: "none"
}

class InputMultiSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            wrapperRef: null,
            filter: "",
            value: this.props.value,
            toggle: false,
            checkedAll: false
        }
    }

    handleChangeFilter = (e) => {
        this.setState({ filter: e.target.value })
    }

    handleToggle = () => {
        this.setState({ filter: "", toggle: (this.state.toggle ? false : true) })
    }

    handleSelect = (val, lab) => {
        let index = this.state.value.findIndex(x => x.id === val)
        if (index !== -1) {
            this.state.value.splice(index, 1)
        } else {
            this.state.value.push({
                id: val,
                label: lab
            })
        }
        this.setState({ ...this.state })
        if (this.state.value.length === this.props.items.length) {
            this.setState({ checkedAll: true })
        } else {
            this.setState({ checkedAll: false })
        }
        if (this.props.onChange !== undefined) {
            let callValue = []
            for (const item of this.state.value) {
                callValue.push(item.id)
            }
            this.props.onChange({ target: { name: this.props.name, value: callValue } })
        }
    }

    handleRemove = (i) => {
        this.state.value.splice(i, 1)
        this.setState({ ...this.state })
        if (this.state.value.length === this.props.items.length) {
            this.setState({ checkedAll: true })
        } else {
            this.setState({ checkedAll: false })
        }
        if (this.props.onChange !== undefined) {
            let callValue = []
            for (const item of this.state.value) {
                callValue.push(item.id)
            }
            this.props.onChange({ target: { name: this.props.name, value: callValue } })
        }
    }

    handleSelectAll = (e) => {
        let checked = !this.state.checkedAll
        let newValue = []
        if (checked) {
            for (const item of this.props.items) {
                newValue.push({
                    id: item[this.props.itemValue],
                    label: item[this.props.itemLabel]
                })
            }
        }
        this.setState({ value: [...newValue] })
        this.setState({ checkedAll: checked })
        if (this.props.onChange !== undefined) {
            let callValue = []
            for (const item of newValue) {
                callValue.push(item.id)
            }
            this.props.onChange({ target: { name: this.props.name, value: callValue } })
        }
    }

    handleClickOutside = (e) => {
        if (this.state.wrapperRef && !this.state.wrapperRef.contains(e.target)) {
            this.setState({ filter: "", toggle: false })
        }
    }

    setWrapperRef = (node) => {
        this.setState({ wrapperRef: node })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value.length > 0 && nextProps.items.length > 0) {
            let newValue = []
            for (const item of nextProps.items) {
                if (nextProps.value.includes(item[nextProps.itemValue])) {
                    newValue.push({
                        id: item[nextProps.itemValue],
                        label: item[nextProps.itemLabel]
                    })
                }
            }
            this.setState({ value: newValue })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    render() {
        return (
            <div className="dropdown" ref={this.setWrapperRef}>
                <div className="form-control dropdown-toggle select-search pointer" id={this.props.id} onClick={this.handleToggle} style={this.props.disabled ? readOnly : null}>
                    {(this.state.value && this.state.value.length > 0) ?
                        <Fragment>
                            <div className="row">
                                {this.state.value.map((item, i) => {
                                    return (
                                        <div key={i} className="col-auto bg-gray px-2 mx-1 rounded" onClick={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
                                            {item.label}
                                            <i className="fa fa-times-circle small text-muted ml-2 pointer" onClick={() => this.handleRemove(i)}></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment> : <p className="my-0 text-muted">{this.props.placeholder}</p>
                    }
                </div>
                <div className={"dropdown-menu w-100" + (this.state.toggle ? " show" : "")} aria-labelledby="dropdownSearch">
                    <div className="px-2">
                        <input type="text" className="form-control form-control-sm" onChange={this.handleChangeFilter} value={this.state.filter} />
                    </div>
                    <div className="dropdown-divider"></div>
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {(this.props.selectAll) ?
                            <div className="dropdown-item pointer position-relative" onClick={this.handleSelectAll}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={this.state.checkedAll} onChange={() => { }} />
                                    <label className="form-check-label">เลือกทั้งหมด</label>
                                </div>
                            </div> : null
                        }
                        {this.props.items.map((item, i) => {
                            var rx = new RegExp(this.state.filter, 'i')
                            return ((item[this.props.itemLabel].match(rx)) ?
                                <div key={i} className="dropdown-item pointer position-relative" onClick={() => this.handleSelect(item[this.props.itemValue], item[this.props.itemLabel])}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={(this.state.value.find(x => x.id === item[this.props.itemValue])) ? true : false} onChange={() => { }} />
                                        <label className="form-check-label">{item[this.props.itemLabel]}</label>
                                    </div>
                                </div> : null
                            )
                        })}
                    </div>
                </div>
                <input type="hidden" name={this.props.name} value={(this.props.value.length > 0 ? JSON.stringify(this.props.value) : "")} required={this.props.required} onChange={() => { }} />
            </div>
        )
    }
}

InputMultiSelect.defaultProps = {
    id: "dropdownSearch",
    name: "dropdown-search",
    value: [],
    placeholder: "เลือก",
    items: [],
    itemValue: "value",
    itemLabel: "label",
    required: false,
    disabled: false,
    selectAll: false
}

export default InputMultiSelect