import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Alert from "../functions/alert"
import Valid from "../functions/validation"
import MainLayout from "../components/main-layout"
import CallActionUI from "./call-action-ui"

class CallAction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                loading : true
            },
            list: []
        }
    }

    handleChange = (e, i) => {
        var list = this.state.list
        if(e.target.type === "checkbox"){
            list[i][e.target.name] = (e.target.checked) ? "1" : "0"
        } else {
            list[i][e.target.name] = e.target.value
        }
        this.setState({ list: list })
    }

    handleSave = () => {
        var valid = Valid.form("submit-form")
        if (valid) {
            var params = this.state.list
            axios.post(CONS.API_CALLACTION + "/update", params, {
                processData: false,
                contentType: false,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
                }
            }
            ).then((response) => {
                if (response.data.status === "success") {
                    Alert.showMsg('บันทึกสำเร็จ')
                } else {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login_token')
                    this.props.history.push(CONS.URL_LINK + '/login?auth=false')
                } else {
                    console.log(error)
                }
            })
        }
    }

    loadData = () => {
        axios.post(CONS.API_CALLACTION + "/list", {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
               this.setState({list : response.data.list, attr : {...this.state.attr, loading : false}})
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, this.loadData)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="callaction" authorized="callaction" onCheckToken={this.checkToken}>
                <CallActionUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(CallAction)

