import React from 'react'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../constants"
import Format from "../functions/format"
import MainLayout from "../components/main-layout"
import ReportProblemsUI from "./report-problem-ui"
import dateFns from 'date-fns'

class ReportProblems extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attr: {
                access_token: "",
                offset: 1,
                max_row: 10,
            },
            date: [
                dateFns.startOfMonth(dateFns.addDays(new Date(), -1)),
                dateFns.endOfMonth(dateFns.addDays(new Date(), -1))
            ],
            list: []
        }
    }

    handleChange = (e) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    handleChangeDate = (date) => {
        this.setState({ date: date }, this.loadList)
    }

    handlePageClick = (e) => {
        var attr = this.state.attr
        var rel = e.target.dataset.index
        if (rel === "-1") rel = parseInt(attr.offset) - 1
        if (rel === "+1") rel = parseInt(attr.offset) + 1
        attr.offset = parseInt(rel)
        this.setState({ attr: attr })
    }

    exportExcel = () => {
        var params = {
            since : Format.convertDateToString(this.state.date[0]),
            until : Format.convertDateToString(this.state.date[1])
        }
        axios.post(CONS.API_REPORT_PROBLEM + "/export", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
                fetch(response.data.path).then(response => {
                    response.blob().then(blob => {
                        let today = new Date()
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = "REPORT_PROBLEM_"+today.getFullYear()+String(today.getMonth() + 1).padStart(2, '0')+String(today.getDate()).padStart(2, '0');
                        a.click();
                    });
                }).catch(error => console.error('Error:', error))
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    loadList = () => {
        var params = {
            since : Format.convertDateToString(this.state.date[0]),
            until : Format.convertDateToString(this.state.date[1])
        }
        axios.post(CONS.API_REPORT_PROBLEM + "/list", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + this.state.attr.access_token
            }
        }
        ).then((response) => {
            if (response.data.status === "success") {
               this.setState({list : response.data.list})
            }
        }).catch((error) => {
            if(error.response.status === 401){
                localStorage.removeItem('login_token')
                this.props.history.push(CONS.URL_LINK + '/login?auth=false')
            } else {
                console.log(error)
            }
        })
    }

    checkToken = (access_token) => {
        this.setState({ attr: { ...this.state.attr, access_token: access_token } }, this.loadList)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <MainLayout active="reportproblem" authorized="reportproblem" onCheckToken={this.checkToken}>
                <ReportProblemsUI {...this} />
            </MainLayout>
        )
    }
}

export default withRouter(ReportProblems)

