import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"
import Editor from '../components/editer'
import CONS from "../constants"

class ServicesEntryUI extends React.Component {
    render() {
        var attr = this.props.state.attr
        var data = this.props.state.data

        return (
            <Fragment>
                <p className="text-title">{this.props.title}</p>
                {(attr.loading) ?
                    <div className="row mx-0 text-center align-items-center h-loding">
                        <div className="col-12">
                            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem", borderWidth: ".5em" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-3">กำลังโหลดข้อมูล.....</p>
                        </div>
                    </div> :
                    <div className="card">
                        <div className="card-body">
                            <form id="submit-form">
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="title_th" className="bold">ชื่อหน้า (TH) *</label>
                                        <input type="text" name="title_th" id="title_th" className="form-control" value={data.title_th} onChange={this.props.handleChange} required readOnly={attr.disabled} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="title_en" className="bold">ชื่อหน้า (EN) *</label>
                                        <input type="text" name="title_en" id="title_en" className="form-control" value={data.title_en} onChange={this.props.handleChange} required readOnly={attr.disabled} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="word_th" className="bold">คำโปรย (TH) *</label>
                                        <Editor data={data.word_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "word_th" })} disabled={attr.disabled} uploadUrl={CONS.API_SERVICE + "/upload"} small={true}/>
                                        <input type="hidden" id="word_th" name="word_th" value={data.word_th} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="word_en" className="bold">คำโปรย (EN) *</label>
                                        <Editor data={data.word_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "word_en" })} disabled={attr.disabled} uploadUrl={CONS.API_SERVICE + "/upload"} small={true}/>
                                        <input type="hidden" id="word_en" name="word_en" value={data.word_en} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label className="bold">รูปปก *</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="image" name="image" onChange={this.props.handleChangeFile} required={(data.old_image !== "" && data.old_image !== undefined) ? false : true} disabled={attr.disabled} />
                                            <label className="custom-file-label" htmlFor="image">{data.name_image}</label>
                                            <input type="hidden" id="old_image" name="old_image" defaultValue={data.old_image} />
                                        </div>
                                        <small className="form-text text-muted">รองรับไฟล์นามสกุล .jpg .png และต้องมีขนาดไม่เกิน 5 MB</small>
                                        {(data.old_image !== "" && data.old_image !== undefined) ? <img src={data.old_image} alt="thumbnail" className="w-100 max-w-400px" /> : null}
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="description_th" className="bold">เนื้อหา (TH) *</label>
                                        <Editor data={data.description_th} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "description_th" })} disabled={attr.disabled} uploadUrl={CONS.API_SERVICE + "/upload"}/>
                                        <input type="hidden" id="description_th" name="description_th" value={data.description_th} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-7 form-group">
                                        <label htmlFor="description_en" className="bold">เนื้อหา (EN) *</label>
                                        <Editor data={data.description_en} onChange={(e, editor) => this.props.handleChangeEditor({ value: editor.getData(), name: "description_en" })} disabled={attr.disabled} uploadUrl={CONS.API_SERVICE + "/upload"}/>
                                        <input type="hidden" id="description_en" name="description_en" value={data.description_en} />
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="btn btn-success" onClick={this.props.handleSave} disabled={attr.disabled}>บันทึก</button>
                                        <input type="hidden" id="id" name="id" defaultValue={data.id} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(ServicesEntryUI)

